<template>
    <div class="tdt-map">
        <!-- <div class="weather">
            <div class="time_box">
                <span class="data">{{ nowYear }}{{ nowDate }}&nbsp;{{ nowWeek }}</span>
                <span class="time">&nbsp;&nbsp;{{ nowTime }}</span>
            </div>
            <iframe width="240" height="30" frameborder="0" scrolling="no" hspace="0"
                src="https://i.tianqi.com/?c=code&a=getcode&id=34&color=ffffff&py=yunyangqu&icon=1"></iframe>
        </div> -->

        <DiaLog :title="title" ref="DiaLog" width="46%" :height="height" @closeDialog="closeDialog">
            <!-- 0环境监控，1雨量，2水质 -->
            <Jessibuca v-if="tuiliuFlv !== '' && source == 0" :videoUrl="tuiliuFlv"  style="width: 100%; height: 100%">
            </Jessibuca>

            <div v-else-if="source == 1" style="width: 100%;height: 100%;">
                <div id="rain-chart" style="width: 100%;height: 100%;"></div>
            </div>
            <div v-else-if="source == 2 || source == 3">
                <el-table :data="qulityData" v-loading="loading" element-loading-text="拼命加载中"
                    element-loading-spinner="el-icon-loading" element-loading-background="#003d67">
                    <el-table-column v-for="(item, index) in setHeader" :key="index" :prop="item.prop"
                        :label="item.label" :width="item.width">
                    </el-table-column>
                </el-table>
            </div>
            <!--  吴浩添加 开始 -->
            <ul class="yt-control" v-if="tuiliuFlv !== '' && source == 0 && isYt == true">
                <li title="镜头上移">
                    <img src="@/images/yt/yt-up.png" @mousedown="cameraYtControl(selectDevice, 'tilt')"
                        @mouseup="cameraYtClose(selectDevice)">
                </li>
                <li title="镜头下移">
                    <img src="@/images/yt/yt-down.png" @mousedown="cameraYtControl(selectDevice, '-tilt')"
                        @mouseup="cameraYtClose(selectDevice)">
                </li>
                <li title="镜头左移">
                    <img src="@/images/yt/yt-left.png" @mousedown="cameraYtControl(selectDevice, '-pan')"
                        @mouseup="cameraYtClose(selectDevice)">
                </li>
                <li title="镜头右移">
                    <img src="@/images/yt/yt-right.png" @mousedown="cameraYtControl(selectDevice, 'pan')"
                        @mouseup="cameraYtClose(selectDevice)">
                </li>
                <li title="镜头放大">
                    <img src="@/images/yt/yt-big.png" @mousedown="cameraYtControl(selectDevice, 'zoom')"
                        @mouseup="cameraYtClose(selectDevice)">
                </li>
                <li title="镜头缩小">
                    <img src="@/images/yt/yt-small.png" @mousedown="cameraYtControl(selectDevice, '-zoom')"
                        @mouseup="cameraYtClose(selectDevice)">
                </li>
                <li title="镜头顺时针旋转" v-if="false">
                    <img src="@/images/yt/yt-scan.png" @mousedown="cameraYtControl(selectDevice, 'rotate')"
                        @mouseup="cameraYtClose(selectDevice)">
                </li>
                <li title="镜头逆时针旋转" v-if="false">
                    <img src="@/images/yt/yt-scan.png" @mousedown="cameraYtControl(selectDevice, '-rotate')"
                        @mouseup="cameraYtClose(selectDevice)">
                </li>
            </ul>
            <!--  吴浩添加 结束 -->
        </DiaLog>

        <!-- 环境监测 -->
        <div class="three-legend-check safaf animate__animated animate__fadeInDown  " v-if="showEnvironmental">
            <el-checkbox-group v-model="checkListEnvironmental" @change="handleCheckChangeEnvironmental">
                <el-checkbox :label="item.label" v-for="(item, index) in EnvironmentalList" :key="index"></el-checkbox>
            </el-checkbox-group>
        </div>

        <!-- 视频巡检 -->
        <div v-if="this.router" class="three-legend-check safaf animate__animated animate__fadeInDown   vap-box">
            <el-switch v-model="videoAutoPlay" active-text="视频巡检" on @change="AutoPlayChange"></el-switch>
        </div>

        <div v-show="videoAutoPlay" class="dynamic-add" id="dynamic-add2">
            <Jessibuca v-if="tuiliuFlv !== '' && source == 0" :videoUrl="tuiliuFlv"
                 style="width: 416px; height: 243px;left:10px;top:10px">
            </Jessibuca>
        </div>

        <div id="map"></div>
    </div>
</template>
<script>
import geojsonObj from './pulsePlogn3.geojson';
import Pulse from './pulse2.geojson';
import Danger from './danger.geojson'
import warnn from '@/images/warnn.png'
import smoke from '@/images/smoke.gif'
import { pointArr } from './data.js';
import { createEChart, geGradientLineChart } from "_u/chart.js";
import { getstreamaddr, getmoniitem, getlargegreenhousedatas, ytControl } from "_a/login";


var map = {}, loca = {}, markerdynamic = {};

export default {
    watch: {
        '$route': {
            handler: function (to, from) {
                this.routerPath = to.path;
                if (to.path === '/base' || to.path === '/') {//流域概况
                    this.router = true;
                    this.zoom = 11.8;
                    setTimeout(() => {
                        this.markCameraPro(this.pointArr, '')
                    }, 1000)
                } else {
                    this.router = false;
                }
                if (to.path === '/LandslideMonitoring') {//岸线管理
                    setTimeout(() => {
                        this.drawDangerWater()
                    }, 500)
                } else if (to.path === '/EnvironmentalMonitoring') {//环境监测
                    this.bottom = 40;
                    this.showEnvironmental = true;
                    setTimeout(() => {
                        this.waterQuality();
                    }, 500)
                } else if (to.path === '/Develop') {//产业发展
                    this.show = false;
                    setTimeout(() => {
                        this.develop();
                    }, 500)
                } else if (to.path === '/SocialCoGovernance') {//社会共治
                    setTimeout(() => {
                        this.warnPrompt([]);
                        this.markCameraPro(this.pointArr, 'Social')
                    }, 500)
                } else if (to.path === '/EarlyWarningAnalysis') {//预警分析
                    setTimeout(() => {
                        this.warnPrompt([]);
                        this.markCameraPro(this.pointArr, 'early')
                    }, 500)
                }
            },
            deep: true,
            immediate: true
        }
    },
    data() {
        return {
            selectDevice: {}, // 吴浩增加 存入选中设备信息
            isYt: false,
            interval: null,
            videoAutoPlay: true,
            tuiliuFlv: '',
            EnvironmentalList: [{
                label: '水质监测'
            }, {
                label: '断面监测'
            }, {
                label: '岸线实时监控点'
            }, {
                label: '森林防火监测点'
            }, {
                label: '滑坡监测点'
            }, {
                label: '垃圾分类点'
            }, {
                label: '厕所改造点'
            }],
            checkListEnvironmental: ['水质监测', '断面监测', '岸线实时监控点', '森林防火监测点', '滑坡监测点', '垃圾分类点', '厕所改造点'],
            showEnvironmental: false,
            routerPath: '',
            loading: false,
            height: '600px',
            bottom: 270,
            xkfbData: {
                xData: ['10:00', '12:00', '14:00', '16:00', '18:00', '20:00', '22:00'],
                yData: [0.1, 0.2, 0.1, 0, 0, 2.3, 1.6],
                unit: 'mm'
            },
            setHeader: [{
                prop: 'itemName',
                label: '名称',
            }, {
                prop: 'monivalue',
                label: '监测数值',
            }, {
                prop: 'bUnit',
                label: '计量单位',
            },
            ],
            qulityData: [],
            source: '',
            title: '',
            show: true,
            nowDate: '',
            nowTime: '',
            nowYear: '',
            nowWeek: '',
            tdtKey: 'aab585f120039de2a433b0481ebbe555',
            loca: {},
            // <!-- 0环境监控camera，1雨量rain，2水质water -->
            pointArr,
            infoWindows: '',
            infoWindows2: '',
            infoWindows3: '',
            router: true,
            zoom: 11.8,
            timer: null,
            arr3: [],
            warnn: {
                lnglat: [110.839847, 32.97471],
                name: '水位超过警戒线',
                address: '老村部水源地区域'
            },
            marksAll: [], //点位图层
            marksAllriver: [], //流域图层
            center: [110.840097, 32.980444],
            randomIndex: '',
        }
    },
    destroyed() {
        clearInterval(this.timer);
        clearInterval(this.interval);
        window.removeEventListener('resize', this.handleResize);

        map.remove(map.getLayers(), map.getAllOverlays())
        map && map.destroy();
        loca && loca.destroy();
        document.querySelector(`canvas.amap-layer`)?.getContext('webgl')?.getExtension('WEBGL_lose_context')?.loseContext()
    },
    mounted() {
        this.initMap();
        this.setNowTimes();
        setInterval(() => {
            this.setNowTimes();
        }, 1000);
        // this.handleResize();
        // window.addEventListener('resize', this.handleResize);
        // this.loadMapScenario()
    },
    unMounted() {
        clearInterval(this.setNowTimes);
    },
    beforeDestroy() {
        // 销毁地图
        if (map) {
            map.destroy();
        }
    },
    methods: {
        loadMapScenario() {
            map = new Microsoft.Maps.Map(document.getElementById('map'), {
                credentials: 'Av8AcViWMuCyl4gWAyLZBLG72faZ5lawWnPKnJ9R6T87m-Rx51ZB1h2zrSxDZW0_',
                center: new Microsoft.Maps.Location(32.980444, 110.840097),
                mapTypeId: Microsoft.Maps.MapTypeId.canvasDark,
                zoom: this.zoom,
                showMapTypeSelector: false, // 将选项框隐藏
                showDashboard: false // 将点击按钮隐藏
            })
        },
        fnResize() {
            let screenWidth = document.body.clientWidth || document.documentElement.clientWidth;
            let screenHeight = document.body.clientHeight || document.documentElement.clientHeight;
            let defWidth = 1920;
            let defHeight = 1080;
            let xScale = screenWidth / defWidth;
            let yScale = screenHeight / defHeight;
            return {
                xScale, yScale
            }
        },
        handleResize() {
            let scaleWH = this.fnResize();
            let mapContent = document.getElementById("map");
            mapContent.style.transform = `scale(${1 / scaleWH.xScale},${1 / scaleWH.yScale
                })`
        },
        setNowTimes() {
            let myDate = new Date();
            let wk = myDate.getDay();
            let mm = String(
                myDate.getMonth() + 1 < 10
                    ? '0' + Number(myDate.getMonth() + 1)
                    : myDate.getMonth() + 1
            );

            let dd = String(
                myDate.getDate() < 10 ? '0' + myDate.getDate() : myDate.getDate()
            );

            let hou = String(
                myDate.getHours() < 10 ? '0' + myDate.getHours() : myDate.getHours()
            );
            let min = String(
                myDate.getMinutes() < 10
                    ? '0' + myDate.getMinutes()
                    : myDate.getMinutes()
            );
            let sec = String(
                myDate.getSeconds() < 10
                    ? '0' + myDate.getSeconds()
                    : myDate.getSeconds()
            );
            let weeks = [
                '星期日',
                '星期一',
                '星期二',
                '星期三',
                '星期四',
                '星期五',
                '星期六'
            ];
            let week = weeks[wk];
            this.nowDate = mm + '月' + dd + '日';
            this.nowTime = hou + ':' + min + ':' + sec;
            this.nowWeek = week;
            this.nowYear = myDate.getFullYear() + '年';
        },
        initMap() {
            const tdsUrl = 'https://t{0,1,2,3,4,5,6,7}.tianditu.gov.cn/DataServer';
            map = new AMap.Map('map', {
                center: this.center,
                mapStyle: "amap://styles/5b0468d9ffb143d7abb75e1b5b8a9538",
                disableSocket: true,
                showLabel: false,
                labelzIndex: 130,
                zoom: this.zoom,
                viewMode: "3D",
                pitch: 30,
                pitchEnable: true,
                rotateEnable: true,
                jogEnable: false,
                defaultCursor: "grab",
                terrain: true,
                layers: [
                    new AMap.createDefaultLayer({
                        zooms: [3, 20],
                        visible: false,
                        opacity: 1,
                        zIndex: 0
                    }),
                    // 高德地图
                    // new AMap.TileLayer.Satellite()
                    // 天地图
                    new AMap.TileLayer({
                        getTileUrl: `${tdsUrl}?T=img_w&tk=${this.tdtKey}&x=[x]&y=[y]&l=[z]`,
                    }),
                    //注记图层
                    new AMap.TileLayer({
                        getTileUrl: `${tdsUrl}?T=cia_w&tk=${this.tdtKey}&x=[x]&y=[y]&l=[z]`,
                    }),
                ]
            });
            let _this = this;
            let geojson = new AMap.GeoJSON({
                geoJSON: geojsonObj,
                getPolygon: function (geojson, lnglats) {
                    return _this.setGeoserver(geojson, lnglats)
                }
            });
            map.add(geojson);
            loca = new Loca.Container({
                map
            });

            _this.pulseLine()

            if (this.routerPath === '/Develop') {
                map.clearMap();
                loca.clear();
                clearInterval(this.timer)
            }
            if (_this.router) {
                loca.animate.start();
                setTimeout(_this.animate(14.9, [110.84586988101196, 32.97844173335813]), 2000);
                _this.dynamicAdd()
            }
            // });

            map.on('mapmove', function (e) {
                // clearInterval(this.timer)
            }).on('movestart', (e) => {
                // clearInterval(this.timer)
            }).on('moveend', (e) => {
            }).on('mousewheel', (e) => {
                // clearInterval(this.timer)
            }).on('click', (e) => {
                console.log(e.lnglat.getLng() + ',' + e.lnglat.getLat())
            }).on('mousemove', (e) => {
                // console.log(e.lnglat.getLng() + ',' + e.lnglat.getLat())
            }).on('zoomend', (e) => {
                this.center = map.getCenter();
                // console.log(map.getZoom())
            });
        },
        // 地图镜头飞行-进入页面视角由远及近
        animate(zoom, center = [110.834662,32.966904]) {
            var speed = 1;
            map.setZoom(11.8, true);
            map.setPitch(0, true);
            map.setCenter(center, true);

            loca.viewControl.addAnimates([{
                pitch: {
                    value: 0,
                    control: [[0, 20], [1, 0]],
                    timing: [0, 0, 0.8, 1],
                    duration: 3000 / speed,
                },
                zoom: {
                    value: zoom,
                    control: [[0, 13], [1, zoom]],
                    timing: [0, 0, 0.8, 1],
                    duration: 3000 / speed,
                },
            }], function () {

            });
        },
        // 绘制多边形-区域(比如水库区域)
        setGeoserver(geojson, lnglats) {
            let polygon;
            if (lnglats.length > 0) {
                polygon = new AMap.Polygon({
                    path: lnglats,
                    fillColor: '#27E1DB',
                    // strokeColor: '#306089'
                });
            }
            const overlayGroup = new AMap.OverlayGroup([polygon]);
            this.marksAllriver.push(polygon)
            return overlayGroup;
        },
        clearMark(marker) {
            if (marker) {
                marker.setMap(null);
                marker = null;
            }
        },
        // 根据地图经纬度转化为屏幕坐标
        updateLabelPosition(marker) {
            var customLabel = document.getElementById('dynamic-add2');
            var pixel = map.lngLatToContainer(marker);
            customLabel.style.left = pixel.getX() - 530 + 'px';
            customLabel.style.top = pixel.getY() - 435 + 'px';
            if (this.videoAutoPlay) {
                customLabel.style.display = 'block';
            }
        },
        // 创建点位(摄像头，水质监控，雨量监控，router===''则全部显示，其他根据pointArr中router标注的内容传参显示)
        markCameraPro(arr2, router) {
            let arr = [];
            if (router !== '') {
                if (arr2.length > 0) {
                    arr2.map(item => {
                        if (item.router.indexOf(router) >= 0) {
                            arr.push(item)
                        }
                    })
                }
            } else {
                arr = arr2
            }

            let _this = this
            this.arr3 = []
            arr.map(item => {
                let marker = new AMap.Marker({
                    position: [item.lnglat[0], item.lnglat[1]],
                    icon: item.type,
                    anchor: 'bottom-center',
                    offset: new AMap.Pixel(16, -45),
                    // offset: new AMap.Pixel(Math.floor(Math.random() * 100), Math.floor(-Math.random() * 40)),
                    zIndex: 28,
                    info: item
                });
                this.marksAll.push(marker)
                marker.setMap(map);
                /* 新增-巡检 */
                if (this.router) {//仅在流域区域巡检
                    if (item.source === 0) {
                        this.arr3.push(item);
                    }
                }

                marker.on('click', (e) => {
                    this.videoAutoPlay = false
                    if (markerdynamic.className) {
                        markerdynamic.setMap(null)
                    }
                    this.showDialog(e.target._opts.info)
                    this.$refs.DiaLog.openDialog({ pageSize: 10, pageNo: 1 });
                }).on('mouseover', () => {
                    _this.createInfoWindow(item)
                }).on('mouseout', () => {
                    if (_this.infoWindows !== '' && _this.infoWindows !== null) {
                        _this.infoWindows.close()
                    }
                });
            })
            if (this.router) {
                this.showInfoWIndow2(this.arr3)
            }
        },
        AutoPlayChange(e) {
            //控制是否视频随机
            if (!e) {
                if (markerdynamic.className) {
                    markerdynamic.setMap(null)
                }
            }
        },
        showInfoWIndow2(arr) {//视频随机显示
            if (arr.length > 0) {
                this.timer = setInterval(() => {
                    if (markerdynamic.className) {
                        var customLabel = document.getElementById('dynamic-add2');
                        customLabel.style.display = 'none';
                        markerdynamic.remove()
                    }
                    this.$nextTick(() => {
                        this.randomIndex = parseInt(Math.random() * arr.length);
                        if (this.videoAutoPlay) this.showDynamicTime(arr[this.randomIndex])
                    })
                }, 10000)
            }
        },
        // 云台控制 - 移动关闭 wuhao添加
        cameraYtClose(item) {
            var params = {
                "streamId": item.params,
                "pan": 0,
                "tilt": 0,
                "zoom": 0,
                "rotate": 0
            }
            ytControl(params).then((res) => {
                if (res.success == false) {
                    this.$message.error('停止失败');
                }
            });
        },
        //云台控制 - 移动开始 wuhao添加
        cameraYtControl(item, type) {
            let pan = 20;
            let tilt = 20;
            let zoom = 20;
            let rotate = 20;
            if (type == "tilt") {
                zoom = 0;
                pan = 0;
                tilt = 20;
                rotate = 0;
            } else if (type == "-tilt") {
                zoom = 0;
                pan = 0;
                tilt = -20;
                rotate = 0;
            } else if (type == "pan") {
                zoom = 0;
                pan = 20;
                tilt = 0;
                rotate = 0;
            } else if (type == "-pan") {
                zoom = 0;
                pan = -20;
                tilt = 0;
                rotate = 0;
            } else if (type == "zoom") {
                zoom = 20;
                pan = 0;
                tilt = 0;
                rotate = 0;
            } else if (type == "-zoom") {
                zoom = -20;
                pan = 0;
                tilt = 0;
                rotate = 0;
            } else if (type == "rotate") {
                zoom = 0;
                pan = 0;
                tilt = 0;
                rotate = 20;
            } else if (type == "-rotate") {
                zoom = 0;
                pan = 0;
                tilt = 0;
                rotate = -20;
            }
            var params = {
                "streamId": item.params,
                "pan": pan,
                "tilt": tilt,
                "zoom": zoom,
                "rotate": rotate
            }
            ytControl(params).then((res) => {
                if (res.success == false) {
                    this.$message.error('控制失败');
                }
            });
        },
        //视频巡检
        showDynamicTime(item) {
            console.log(this.timer, 'this.timer');
            getstreamaddr({ stream: item.params }).then((res) => {
                if (res.ret_code == 0) {
                    this.$nextTick(() => {
                        const data = JSON.parse(res.data);
                        this.tuiliuFlv = '';
                        this.$nextTick(() => {
                            this.tuiliuFlv = data.data.ws_flv;
                        })
                        map.setCenter(item.lnglat)
                        this.updateLabelPosition(item.lnglat);
                        map.on('moveend', () => {
                            this.updateLabelPosition(item.lnglat)
                        });
                        map.on('mousewheel', () => {
                            this.updateLabelPosition(item.lnglat)
                        })
                        map.on('zoomend', this.updateLabelPosition(item.lnglat));
                        markerdynamic = new AMap.Marker({
                            position: [item.lnglat[0], item.lnglat[1]],
                            icon: item.type,
                            anchor: 'bottom-center',
                            offset: new AMap.Pixel(16, -45),
                            zIndex: 28,
                            info: item
                        });
                        markerdynamic.setMap(map);
                        markerdynamic.setLabel({
                            offset: new AMap.Pixel(-60, 0),
                            content: `<div class='dynamic-add3'></div > `,
                            direction: 'top',
                        });
                    })
                } else {
                    this.$message.error('获取视频地址失败');
                }
            });
            // setTimeout(() => {
            //     markerdynamic.remove()
            // }, 10000)
        },
        closeDialog() {
            this.tuiliuFlv = '';
        },
        getRainChart() {
            geGradientLineChart(this.xkfbData.xData, this.xkfbData.yData, this.xkfbData.unit).then((res) => {
                const el = document.getElementById("rain-chart");
                createEChart(el, res, () => {
                    return {
                        width: el.clientWidth,
                        height: el.clientHeight,
                    };
                });
            });
        },
        getstreamaddr(params) {
            getstreamaddr({ stream: params }).then((res) => {
                if (res.ret_code == 0) {
                    const data = JSON.parse(res.data);
                    this.tuiliuFlv = data.data.ws_flv;
                } else {
                    this.$message.error('获取视频地址失败');
                }
            });
        },
        showDialog(info) {
            // wuhao添加 获取当前设备信息，使用设备id控制设备
            this.selectDevice = info;
            if (this.selectDevice != null) {
                let num = this.selectDevice.params.replace("yyxly", "");
                let intNum = parseInt(num); // 结果是 123
                if (intNum > 18 || intNum == 18) {
                    this.isYt = false;
                } else if (intNum == 2 || intNum == 4 || intNum == 6 || intNum == 8 || intNum == 10) {
                    this.isYt = false;
                } else {
                    this.isYt = true;

                }

            }
            this.source = info.source;
            this.tuiliuFlv = '';
            this.qulityData = []
            switch (info.source) {
                case 0:
                    [this.title, this.height] = [info.point, '600px'];
                    this.getstreamaddr(info.params)
                    break;
                case 1:
                    [this.title, this.height] = ['雨量监测', '400px'];
                    setTimeout(() => {
                        this.getRainChart();
                    }, 1000)
                    break;
                case 2:
                    [this.title, this.height] = [info.title ? info.title : '水质监测', '400px'];
                    this.getmoniitems(info)
                    break;
                case 3:
                    [this.title, this.height] = ['大棚监测', '400px'];
                    this.getlargegreenhousedatass(info)
                    break;
                case 4:
                    [this.title, this.height] = [info.point, '400px'];
                    this.getlargegreenhousedatass(info)
                    break;
                default:
                    break
            }
        },
        getmoniitems(item) {
            this.setHeader = [
                {
                    prop: 'itemName',
                    label: '名称',
                }, {
                    prop: 'monivalue',
                    label: '监测数值',
                }, {
                    prop: 'bUnit',
                    label: '计量单位',
                },
            ]
            getmoniitem({ id: item.params }).then((res) => {
                if (res.code == 200) {
                    this.qulityData = res.result.map((item, index) => item)
                }
            })
        },
        getlargegreenhousedatass(item) {
            this.setHeader = [
                {
                    prop: 'name',
                    label: '名称',
                }, {
                    prop: 'humidity',
                    label: '湿度(%)',
                }, {
                    prop: 'temperature',
                    label: '温度(℃)',
                },
            ]
            getlargegreenhousedatas({ id: item.params }).then((res) => {
                if (res.code == 200) {
                    this.$nextTick(() => {
                        this.qulityData = res.result.map((item, index) => item)
                    })
                }
            })
        },
        //点击点位出现弹窗
        createInfoWindow(val) {
            var info = [];
            info.push(`<div div > <span>位置：</span>${val.name}</div >
        <div><span>点位：</span>${val.point}</div>
        <div><span>设备：</span>${val.equipment}</div>
        `);
            this.infoWindows = new AMap.InfoWindow({
                content: info.join("<br/>"),
                offset: new AMap.Pixel(16, -110)
            });
            this.infoWindows.open(map, [val.lnglat[0] + '', val.lnglat[1] + '']);
        },
        createInfoWindow2(val, lnglat) {
            var info = [];
            info.push(`<ul ul ><li><span>样品原编号：</span>${val.name}</li>
        <li><span>pH值(无量纲)：</span>${val.n1}</li>
        <li><span>有机质(g/kg)：</span>${val.n2}</li>
        <li><span>碱解氮(mg/kg)：</span>${val.n3}</li>
        <li><span>有效磷(mg/kg)：</span>${val.n4}</li>
        <li><span>速效钾(mg/kg)：</span>${val.n5}</li>
        </ul >
          `);
            this.infoWindows2 = new AMap.InfoWindow({
                content: info.join("<br/>"),
                offset: new AMap.Pixel(16, 0)
            });
            this.infoWindows2.open(map, [lnglat[0] + '', lnglat[1] + '']);
        },
        createInfoWindow3(val, lnglat) {
            var info = [];
            info.push(`<ul ul class="develops" >
        <li class="develops-item"><div>村名</div><div>农作物</div><div>面积(亩)</div><div>总产量(吨)</div></li>
        <li class="develops-item"><div>${val.name}</div><div>小麦</div><div>${val.info.mai.area}</div><div>${val.info.mai.all}</div></li>
        <li class="develops-item"><div>${val.name}</div><div>玉米</div><div>${val.info.yumi.area}</div><div>${val.info.yumi.all}</div></li>
        <li class="develops-item"><div>${val.name}</div><div>油菜</div><div>${val.info.youcai.area}</div><div>${val.info.youcai.all}</div></li>
        <li class="develops-item"><div>${val.name}</div><div>红薯</div><div>${val.info.hongshu.area}</div><div>${val.info.hongshu.all}</div></li>
        <li class="develops-item"><div>${val.name}</div><div>蔬菜</div><div>${val.info.shucai.area}</div><div>${val.info.shucai.all}</div></li>
        <li class="develops-item"><div>${val.name}</div><div>茶叶</div><div>${val.info.chaye.area}</div><div>${val.info.chaye.all}</div></li>
        <li class="develops-item"><div>${val.name}</div><div>小水果</div><div>${val.info.xiaoshuiguo.area}</div><div>${val.info.xiaoshuiguo.all}</div></li>
        <li class="develops-item"><div>${val.name}</div><div>食用菌</div><div>${val.info.shiyongjun.area}</div><div>${val.info.shiyongjun.all}</div></li>
        </ul >
          `);
            this.infoWindows3 = new AMap.InfoWindow({
                content: info.join("<br/>"),
                offset: new AMap.Pixel(16, -10)
            });
            this.infoWindows3.open(map, [lnglat[0] + '', lnglat[1] + '']);
        },
        //水流线--地图上会动的流域线
        pulseLine() {
            let pLineSource = new Loca.GeoJSONSource({
                data: Pulse
            });
            let pLinelayer = new Loca.PulseLineLayer({
                zIndex: 100,
                opacity: 1,
                visible: true,
                zooms: [2, 22]
            });
            const headColors = [
                '#4da2e3',
                '#066bb3',
                '#07b989',
                '#d0b23b',
                '#ff7700',
                '#c80303',
                '#5d5d5d',
                '#00FFFF'
            ];
            const trailColors = [
                'rgba(77,162,227, 0.2)',
                'rgba(6,107,179, 0.2)',
                'rgba(7,185,137, 0.2)',
                'rgba(208,178,59, 0.2)',
                'rgba(255,119,0, 0.2)',
                'rgba(200,3,3, 0.2)',
                'rgba(94,94,94, 0.2)',
                'rgba(0,255,255, 0.3)'
            ];

            pLinelayer.setStyle({
                altitude: 0,
                lineWidth: (_, feature) => {
                    if (feature.properties.type == 1) {
                        return 10
                    } else {
                        return 3
                    }
                },
                headColor: (_, feature) => headColors[7],
                trailColor: (_, feature) => trailColors[7],
                interval: 0.0005,
                duration: 1000000
            });
            pLinelayer.setSource(pLineSource);
            loca.add(pLinelayer);
            loca.animate.start();
            this.marksAllriver.push(pLinelayer)
        },
        drawDangerWater() {
            let that = this;
            let danger = new AMap.GeoJSON({
                geoJSON: Danger,
                getPolygon: function (geojson, lnglats) {
                    return that.setDangerGeoserver(geojson, lnglats)
                }
            });
            map.add(danger);
            setTimeout(() => {
                this.animate(14.5)
            }, 100)
            this.markCameraPro(this.pointArr, 'lands');
        },
        setDangerGeoserver(geojson, lnglats) {
            let polygon;
            if (lnglats.length > 0) {
                polygon = new AMap.Polygon({
                    path: lnglats,
                    zIndex: 999,
                    fillColor: '#fc7100',
                    fillOpacity: 0.7,
                    strokeColor: '#fc7100',
                });
            }
            const overlayGroup = new AMap.OverlayGroup([polygon]);
            return overlayGroup;
        },
        waterQuality(e) {
            if (!e) {
                this.warnPrompt([]);
            } else {
                this.clearMarkers(this.marksAll)
            }
            let Arr = []
            this.pointArr.map(item => {
                if (this.checkListEnvironmental.length > 0) {
                    this.checkListEnvironmental.map(_item => {
                        if (item.genre == _item) {
                            Arr.push(item)
                        }
                    })
                }
            })

            this.$nextTick(() => {
                this.markCameraPro(Arr, 'pipe');
            })
        },
        // 清除所有标点的函数
        clearMarkers(markers) {
            for (var i = 0; i < markers.length; i++) {
                markers[i].setMap(null);
            }
            this.marksAll = []
        },
        setShadow(params) {
            let data = map.getAllOverlays("marker");
            data.forEach((item) => {
                if (item._opts.animation === "AMAP_ANIMATION_BOUNCE") {
                    item.dom.classList.add("abnormal_marker");
                } else {
                    item.dom.classList.remove("abnormal_marker");
                }
            })
        },

        warnPrompt(markInfoArr, zoom = 14.8) {
            setTimeout(() => {
                this.animate(zoom);
                clearInterval(this.timer)
            }, 10)
            markInfoArr.map(mark => {
                let icon = new AMap.Icon({
                    size: new AMap.Size(100, 100),
                    imageSize: new AMap.Size(100, 100),
                    image: smoke
                });
                let _mark;
                _mark = new AMap.Marker({
                    position: mark.lnglat,
                    icon: warnn,
                    anchor: 'bottom-center',
                    animation: 'AMAP_ANIMATION_BOUNCE',
                    zIndex: 999,
                    bubble: true
                });

                _mark.setMap(map);
                this.marksAll.push(_mark);
                let that = this;
                _mark.setLabel({
                    offset: new AMap.Pixel(10, -5),
                    content: `<ul ul class="warn-marker" ><li><span>警告：</span>${mark.name}</li>
        <li><span>位置：</span>${mark.address}</li></ul >
          `,
                    direction: 'top'
                });
                that.setShadow();
            })
        },
        develop() {
            let _this = this;
            setTimeout(() => {
                this.animate(12.27, [110.853381, 32.904938]);
                clearInterval(this.timer)
                this.markCameraPro(this.pointArr, 'agro');
            }, 10)
        },
        hotMap(data) {
            var geo = new Loca.GeoJSONSource({
                data: data
            });
            var heatmap = new Loca.HeatMapLayer({
                zIndex: 10000,
                opacity: 1,
                visible: true,
                zooms: [2, 22],
            });
            heatmap.setSource(geo, {
                radius: 800,
                unit: 'meter',
                height: 200,
                difference: true,
                gradient: {
                    1: '#FF4C2F',
                    0.8: '#FAA53F',
                    0.6: '#FFF100',
                    0.5: '#7DF675',
                    0.4: '#5CE182',
                    0.2: '#29CF6F',
                },
                value: function (index, feature) {
                    return feature.properties.count;
                },
                opacity: [0, 1],
                heightBezier: [0, 0.53, 0.37, 0.98]
            });
            loca.add(heatmap);
        },

        /* 动态添加点视频显示 */
        dynamicAdd() {

        },

        handleCheckChangeEnvironmental(val) {
            this.checkListEnvironmental = val
            this.$nextTick(() => {
                this.waterQuality(true)
            })
        },
    }
}
</script>
<style lang="scss" scoped>
.yt-control {
    position: absolute;
    bottom: 70px;
    height: 50px;

    li {
        float: left;
        margin-left: 20px;

        img {
            width: 50px;
            height: 50px;
            cursor: pointer;
        }
    }

}

.weather {
    display: flex;
    align-items: center;

    .time_box {
        font-size: 12px;
        font-family: PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-right: 5px;
        margin-top: 3px;
    }
}

/deep/.dynamic-add {
    position: absolute;
    display: none;
    z-index: 9;
    width: 437px;
    height: 265px;
    background: url('@/images/kuang.png') no-repeat;
    background-size: 100% 100%;

    .add-info {
        position: absolute;
        z-index: 9;
        margin: 15px 20px;

        &-item {
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: rgba(2, 43, 46, 0.29);
            border: 1px solid #bedeff;
            width: 140px;
            height: 34px;
            margin: 5px 0;
            padding: 0 5px;
            color: #fff;
            font-size: 12px;
        }

        .big-font {
            font-size: 24px;
            font-family: 'YouSheBiaoTiHei'
        }
    }
}

/deep/.dynamic-add3 {
    width: 123px;
    height: 114px;
    background: url('@/images/line.png') no-repeat;
    background-size: 100% 100%;

}

.tdt-map {
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 1;

    .legend {
        position: absolute;
        color: rgba(255, 255, 255, .8);
        z-index: 20;
        right: 480px;

        &-img {
            width: 35px;
            padding-right: 8px;
        }

        &-item {
            display: flex;
            align-items: center;
            padding: 8px 0;

            &:nth-last-of-type(1) {
                .legend-img {
                    padding: 0 8px 0 5px;
                }
            }
        }
    }

    .three-legend {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        height: 240px;
        position: absolute;
        color: rgba(255, 255, 255, .8);
        z-index: 20;
        right: 480px;
        bottom: 20px;
        background: rgba(20, 59, 89, 0.6);
        padding: 10px 30px 10px 10px;
        border-radius: 8px;

        .legend-item-div {
            height: 16px;
            width: 30px;
            margin-right: 5px;
            border: 1px solid;
        }

        &-check {
            position: absolute;
            right: 490px;
            top: 60px;
            z-index: 99;
            background: rgba(20, 59, 89, 0.6);
            padding: 10px 30px 10px 10px;
            border-radius: 8px;

            /deep/.el-checkbox__label {
                color: #fff;
            }

            /deep/.el-checkbox__input.is-checked .el-checkbox__inner,
            .el-checkbox__input.is-indeterminate .el-checkbox__inner {
                background-color: #13D4EA;
            }
        }

    }

    .safaf {
        top: 120px;
    }

    @keyframes blink {
        0% {
            opacity: 1;
        }

        50% {
            opacity: 0.1;
        }

        100% {
            opacity: 1;
        }
    }

    :deep(.abnormal_marker) {
        .amap-icon {
            animation: blink 2s infinite;

        }
    }

    @keyframes backgroundJump {

        0%,
        100% {
            transform: scale(0.9)
        }

        50% {
            transform: scale(1)
        }
    }

    /deep/.amap-marker {

        &:nth-of-type(1) {
            .amap-icon {
                animation: backgroundJump 2s infinite;
            }
        }

        &:nth-of-type(2) {
            .amap-icon {
                animation: backgroundJump 2s infinite;
            }
        }

        .amap-icon {

            &:hover {
                scale: 1.1;
            }
        }
    }

    /deep/.amap-info-outer {
        box-shadow: none;
    }

    /deep/.amap-info-content {
        color: #E9E9E9;
        padding: 10px 20px;
        background: url('@/images/camera-bg.png') no-repeat;
        background-size: 100% 100%;

        div {
            line-height: 28px;
        }
    }

    /deep/.amap-info-sharp {
        // display: none;
        border-top-color: #198c8cba;
        // bottom: 5px;
    }

    /deep/.amap-marker-label {
        background-color: #ffffff00;
        border: none;
        top: -120px !important;
        left: -100px !important;
    }

    /deep/.join-water {
        padding: 10px 20px;
        background-color: rgba(7, 39, 60, 0.5);
        color: #fff;
        font-size: 18px;
        border: 1px solid #66e4ff;
        border-radius: 8px;
    }

    /deep/.warn-marker {
        padding: 10px 20px;
        background-color: #f36f677a;
        color: #fff;
        font-size: 18px;
        border: 1px solid #f36f67;
        border-radius: 8px;

        li {
            line-height: 32px;
        }
    }

    /deep/.amap-layers {
        &::after {
            content: '';
            // background: radial-gradient(ellipse at center, rgba(8, 112, 248, 0.18) 0%, rgba(8, 112, 248, 0.18) 10%, rgba(0, 30, 255, 0.1) 30%, rgba(0, 0, 0, 1) 100%);
            // background: radial-gradient(circle at center center, rgba(0, 220, 255, 0.18), rgba(0, 76, 255, 0.14) 40%, rgba(0, 0, 0, 1) 100%);//天地图
            // background: radial-gradient(circle at center center, #009fff42, #2900ff24 40%, black 100%); //高德地图
            /*  pointer-events: none;
      background: radial-gradient(circle at center center, #009fff00, #000000a1 90%, black 100%); //高德地图
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 0;
      filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, .3)); */
        }
    }


    #map {
        width: 100%;
        height: 100%;
        background: #104846
    }

    /deep/ .tdt-control>div>img {
        display: none;
    }

    /deep/.amap-logo,
    /deep/.amap-copyright,
    /deep/.amap-info-close {
        display: none !important;
    }


    /deep/.amap-info {
        min-width: 350px;
    }

    /deep/.develops {

        &-item {
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:not(:nth-last-of-type(1)) {
                border-bottom: 1px solid #ffffff2c;
            }

            div {
                width: 80px !important;
                font-size: 12px;
            }
        }
    }

}

/deep/ .vap-box .el-switch__label {
    color: #DCDFE6;
}
</style>