<template>
    <div id="app">

        <router-view />
    </div>
</template>

<script>
export default {
    name: "App",
    data() {
        return {
        };
    },
    methods: {
    },
};
</script>

<style lang="scss">
@import "./assets/styles/style.scss";

.weather {
    position: absolute;
    right: 20px;
    top: 0;
    z-index: 9;
}


.beijing-box {
    width: 488px;
    height: 100%;
    z-index: 2;
    position: relative;
    margin-top: 97px;
    box-sizing: border-box;

    .hideImg {
        position: absolute;
        top: 50%;
        left: 5px;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;
    }


}

.hideImg2 {
    position: fixed;
    top: 50%;
    right: 0px;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
}

.hideImg3 {
    position: fixed;
    top: 50%;
    left: 0px;
    transform: translateY(-50%);
    z-index: 10;
    cursor: pointer;
}

.beijing-box2 {
    width: 488px;
    height: 100%;
    z-index: 2;
    position: relative;
    margin-top: 97px;
    box-sizing: border-box;

    .hideImg4 {
        position: absolute;
        top: 50%;
        right: 5px;
        transform: translateY(-50%);
        z-index: 10;
        cursor: pointer;
    }
}

.beijing-box3 {
    // width: 324px;
    width: 280px;
    height: calc(100% - 250px);
    // background: url('@/beijing3.png') no-repeat;
    background-size: 100% 100%;
    z-index: 2;
    box-shadow: -20px 0px 50px 0px rgba(4, 4, 6, 0.18);
}

.left-box {
    display: flex;
    align-items: center;
}

.items-box {
    justify-content: space-between;
}


.leftBox {
    position: absolute;
    left: 0;
    top: 0;
}

.rightBox {
    position: absolute;
    right: 0;
    top: 0;
}


.el-select-dropdown__item.selected {
    color: #54a391 !important;
}

.el-year-table td.today .cell {
    color: #54a391 !important;
}

.el-year-table td .cell:hover {
    color: #54a391 !important;
}

.el-date-picker__header-label:hover {
    color: #54a391 !important;
}

.el-month-table td.today .cell {
    color: #54a391 !important;
}

.el-month-table td .cell:hover {
    color: #54a391 !important;
}

.el-date-table td.today span {
    color: #54a391 !important;
}

.el-date-table td.available:hover {
    color: #54a391 !important;
}


.el-popover {
    background: linear-gradient(90deg, rgba(20, 59, 89, 0.5) 0%, rgba(40, 40, 40, 0) 100%) !important;
    border: none !important;
}

.popper__arrow::after {
    border-right-color: rgba(20, 59, 89, 0.5) !important;
}

.popper__arrow {
    border-right-color: rgba(20, 59, 89, 0.5) !important;
}

.el-table__empty-text {
    color: #fff;
}
</style>
