<template>
    <div>
        <!-- <div class="notic-bar" @click="open">
            <el-badge :value="1" :max="99" class="item-box">
                <img src="@/images/notice.png" class="notice-img" />
            </el-badge>
            <div class="notice-bar-container">
                <div class="notice-bar__wrap">
                    <ul v-for="(item, index) in list" :key="index" class="notice-bar__wrap_text">
                        <li class="notice-bar__wrap_text_item">
                            <span :class="item.type === '待解决' ? 'redC' : 'bluC'">{{ item.type }}：</span>
                            <span>{{ item.name }}-</span>
                            <span>{{ item.charge }}-</span>
                            <span>{{ item.address }}</span>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
        <DiaLog :title="'详情'" ref="DiaLog" slots="list" :tableData="list" :setHeader="setHeader"></DiaLog> -->
    </div>
</template>

<script>
export default {
    data() {
        return {
            list: [{
                name: '水位超过警戒线，请处理！',
                charge: '李某',
                tmp: '短信',
                type: '已解决',
                address: '黑沟水域'
            }, {
                name: '危险区域人员闯入，请处理！',
                charge: '李某',
                tmp: '短信',
                type: '已解决',
                address: '老村部水源地'
            }],
            setHeader: [
                {
                    prop: 'name',
                    label: '预警内容',
                    width: '250px'
                },
                {
                    prop: 'charge',
                    label: '负责人',

                },
                {
                    prop: 'tmp',
                    label: '通知方式',
                },
                {
                    prop: 'type',
                    label: '预警状态',
                },
                {
                    prop: 'address',
                    label: '预警区域',
                },
            ]
        }
    },
    methods: {
        open(pageSize = 8) {
            this.$refs.DiaLog.openDialog({ pageSize, pageNo: 1 });
        },
    }
}
</script>

<style lang="scss" scoped>
.notic-bar {
    position: fixed;
    z-index: 99;
    top: 15px;
    right: 469px;
    display: flex;
    margin: 5px;
    // max-width: calc(100% - 600px);
    width: 750px;
    border-radius: 5px;
    padding: 2px 5px;
    cursor: pointer;
}

@keyframes backgroundJump {

    0%,
    100% {
        transform: scale(0.8)
    }

    50% {
        transform: scale(1.1)
    }
}

.redC {
    color: #ff7c48;
    font-weight: 800;
}

.bluC {
    color: aqua;
}

.notice-bar-container {
    display: flex;
    width: calc(100% - 30px);
    height: 20px;
    overflow: hidden;
    margin-left: 5px;
}

.notice-img {
    width: 20px;
    height: 20px;
    animation: backgroundJump 2s infinite;
    cursor: pointer;
}

.notice-bar__wrap {
    margin-left: 10px;
    display: flex;
    animation: move 20s linear infinite;
    line-height: 20px;
    color: #f5f6f7;

    .notice-bar__wrap_text {
        width: max-content;
        min-width: 100px;
    }

    .notice-bar__wrap_text_item {
        padding-left: 30px;
    }
}

@keyframes move {
    0% {
        transform: translateX(100%);
    }

    100% {
        transform: translateX(-100%);
    }
}
</style>
