import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import router from "./router";
import ElementUI from "element-ui";
import dataV from "@jiaminghi/data-view";
import Swipers from "_c/Swipers";
import Map from "_c/map";
import "@/font/font.css";
import "@/font/font2.css";
import DiaLog from "_c/DiaLog.vue";
import noticeBar from "_c/noticebar";
import Jessibuca from "_c/jessibuca/index";
import "animate.css";
Vue.use(dataV);
import "element-ui/lib/theme-chalk/index.css";
Vue.use(VueRouter);
Vue.config.productionTip = false;

import CountTo from "vue-count-to"; //引入数字滚动器
Vue.component("count-to", CountTo);
Vue.component("Swipers", Swipers);
Vue.component("Map", Map);
Vue.component("DiaLog", DiaLog);
Vue.component("noticeBar", noticeBar);
Vue.component("Jessibuca", Jessibuca);
Vue.use(ElementUI);
Vue.prototype.$bus = new Vue();
new Vue({
  render: (h) => h(App),
  router,
}).$mount("#app");
