import { req, reqJk, reqApi } from "./index";

// 获取验证码
export function getCode(key) {
  return reqApi.get({
    url: `/jeecg-boot/sys/randomImage/${key}`,
  });
}

// 登录
export function login(data) {
  return reqApi.post({
    url: "/jeecg-boot/sys/login",
    data,
  });
}
// 摄像头
export function getstreamaddr(data) {
  return req.get({
    url: `/api-iot/camera/getstreamaddr?app=yyxly&stream=${data.stream}&mediaServerId=your_server_id`,
    data,
  });
}

// 云台控制
export function ytControl(data) {
  return reqApi.post({
    url: `/jeecg-boot/hik/device/continuous`,
    data,
  });
}

//token
export function accountToken(data) {
  return reqJk.post({
    url: `/api-sso/account/token`,
    data,
  });
}

//获取设备下的监测指标
export function getmoniitem(data) {
  return reqApi.post({
    url: `/jeecg-boot/iot/sensor/getmoniitem?id=${data.id}`,
    data,
  });
}

//指定监测指标下的实时数据
export function getmonidatabyitem(data) {
  return reqApi.post({
    url: `/jeecg-boot/iot/sensor/getmonidatabyitem?id=${data.id}&name=${data.name}`,
    data,
  });
}

//
export function getlargegreenhousedatas(data) {
  return reqApi.post({
    url: `/jeecg-boot/iot/sensor/getlargegreenhousedatas?region=${data.id}`,
    data,
  });
}

//
export function scPhotegraphList(data) {
  return reqApi.get({
    url: `/jeecg-boot/scphotegraph/scPhotegraph/list`,
    data,
  });
}
