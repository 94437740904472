<template>
    <!-- 弹窗 -->
    <el-dialog :width="width" :visible.sync="diaLogVisible" :modal-append-to-body="false" :show-close="false"
        top="10vh">
        <div class="head-box">
            <img class="title-img" src="@/bjall/camera.png" alt="" v-if="slots == 'custom'">
            <img class="title-img" src="@/bjall/table.png" alt="" v-else>
            <span slot="title" class="title-box">{{ title }}</span>
            <img class="close" src="@/bjall/tanchuang2.png" alt="" @click="closeDialog" />
        </div>
        <div class="content" :style="{ height }">
            <slot v-if="slots == 'custom'"></slot>
            <div v-else-if="slots == 'list'">
                <el-table :data="tableData" style="width: 100%;height: 668px;" v-loading="loading"
                    element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading"
                    element-loading-background="#003d67" :show-summary="showSummary" :summary-method="getSummaries"
                    :header-cell-style="{ 'text-align': 'center' }" :cell-style="{ 'text-align': 'center' }">
                    <el-table-column v-for="(item, index) in setHeader" :key="index" :prop="item.prop"
                        :label="item.label" :width="item.width">
                    </el-table-column>
                </el-table>
                <el-pagination layout="prev, pager, next" :page-size="pageSize" :total="total"
                    @current-change="currentChange"  :current-page.sync="currentPage">
                </el-pagination>
            </div>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: "diaLog",
    props: {
        title: {
            type: String,
            default: "",
        },
        width: {
            type: String,
            default: "50%",
        },
        tableData: {
            type: Array,
            default: () => [],
        },
        setHeader: {
            type: Array,
            default: () => [],
        },
        slots: {
            type: String,
            default: "custom", //custom 自定义插槽内容  list 列表
        },
        height: {
            type: String,
            default: "750px",
        },
    },
    data() {
        return {
            loading: true,
            initLoading: false,
            total: 0,
            currentPage: 1,
            diaLogVisible: false,
            pageSize: 8,
            showSummary: false,
            unit: ''
        };
    },
    methods: {
        closeDialog() {
            this.diaLogVisible = false;
            this.$emit('closeDialog')
        },
        currentChange(page) {
            this.initLoading = true
            this.loading = true;
            this.$emit('currentChange', page)
            // this.getTownshipDatasz(page)
        },
        openDialog(item) {
            this.currentPage = item.pageNo
            this.pageSize = item.pageSize
            this.total = item.total || 0
            this.showSummary = item.showSummary || false
            this.unit = item.unit || ''
            this.$nextTick(() => {
                this.getTownshipDatasz(item.pageNo)
            })
        },
        getTownshipDatasz(pageNo) {
            this.$nextTick(() => {
                this.diaLogVisible = true;
            })
            this.initLoading = false
            this.$nextTick(() => {
                this.loading = false;
            })
        },


        getSummaries(param) {
            const { columns, data } = param;
            const sums = [];
            columns.forEach((column, index) => {
                if (index === 0) {
                    sums[index] = '总计';
                    return;
                }
                const values = data.map(item => Number(this.unit ? item[column.property].split(this.unit)[0] : item[column.property]));
                if (!values.every(value => isNaN(value))) {
                    sums[index] = values.reduce((prev, curr) => {
                        console.log(prev, curr);
                        const value = Number(curr);
                        if (!isNaN(value)) {
                            return Number((prev + curr).toFixed(2));
                        } else {
                            return prev;
                        }
                    }, 0);
                    sums[index] += this.unit;
                } else {
                    sums[index] = 'N/A';
                }
            });

            return sums;
        }
    },
};
</script>

<style lang="scss">
.el-dialog {
    text-align: center;
    background-color: transparent !important;

    .el-dialog__header {
        position: absolute;
        top: 54px;
        left: 100px;

        .el-dialog__title {
            font-size: 36px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #fefefe;
        }
    }

    .el-dialog__body {
        padding: 0;
        box-sizing: border-box;
    }

    .head-box {
        width: 100%;
        height: 29px;
        background: url("@/bjall/tanchuang3.png") no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 0 0 65px;
        box-sizing: border-box;
        margin-bottom: 12px;
        position: relative;
    }

    .title-img {
        width: auto;
        height: auto;
        position: absolute;
        top: -49px;
        left: 13px;
    }

    .close {
        width: 16px;
        height: 16px;
        cursor: pointer;
        position: absolute;
        top: -8px;
        right: 0;
    }

    .title-box {
        font-family: YouSheBiaoTiHei;
        font-weight: 400;
        font-size: 28px;
        color: #FFFFFF;
        position: absolute;
        top: -17px;
        left: 102px;
    }

    .content {
        width: 100%;
        // height: 750px;
        background: url("@/bjall/tanchuang.png") no-repeat !important;
        background-size: 100% 100% !important;
        margin: 0;
        padding: 15px;
        box-sizing: border-box;

        video {
            width: 100%;
            height: 100%;
        }

        .el-table {
            background-color: transparent;
        }

        .el-table tr {
            background-color: transparent;
        }

        .el-table th.el-table__cell {
            background-color: transparent;
        }

        .cell {
            width: 100%;
            height: 100%;
            background: -webkit-linear-gradient(90deg, #9cd2ff 0%, #ffffff 100%);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            text-shadow: none !important;
            font-family: YouSheBiaoTiHei;
            font-weight: 400;
            color: #ffffff;
            font-weight: bold;
            // display: flex;
            // align-items: center;
        }

        .el-table--enable-row-hover .el-table__body tr:hover>td.el-table__cell {
            background: transparent;
        }

        .el-table__footer-wrapper tbody td.el-table__cell {
            background: transparent;
        }

        //分页器的样式
        .el-pagination {
            margin-top: 10px;

            .btn-prev {
                background: none;
                color: #fff;
            }

            .btn-next {
                background: none;
                color: #fff;
            }

            .el-pager li {
                background: none;
                color: #ddfbf7;
                font-size: 20px;
            }

            .el-pager li.active {
                color: #409eff;
                font-size: 20px;
            }
        }
    }
}
</style>
