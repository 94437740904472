import _Request from "./req";

// 如果有不同的请求地址，就给它导出两个
const req = new _Request({
  baseURL: "/iotapi",
  timeout: 60000,
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      const token = sessionStorage.getItem("token");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
  },
});
const reqJk = new _Request({
  baseURL: "/ssoapi",
  // baseURL: "https://sso.loyon.cn",
  timeout: 60000,
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      //   const wlwtoken = sessionStorage.getItem("token");
      //   if (wlwtoken) {
      //     config.headers["Authorization"] = "Bearer " + wlwtoken;
      //   } else {
      config.headers["UserName"] = "fenglei";
      config.headers["Password"] = "fenglei123";
      config.headers["AppKey"] = "Tainot.SSO.8UxhGksbZ873li0Eq?aAg";
      //   }
      return config;
    },
  },
});
const reqApi = new _Request({
  baseURL: "/api",
  timeout: 60000,
  interceptors: {
    requestInterceptor: (config) => {
      // 携带token的拦截
      const token = sessionStorage.getItem("wlwtoken");
      if (token) {
        config.headers["Authorization"] = "Bearer " + token;
      }
      return config;
    },
  },
});

export { req, reqJk, reqApi };
