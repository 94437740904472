<template>
    <div :style="{
        width: width + 'px',
        height: height + 'px',
    }" id="container" ref="container"></div>
</template>
<script>
export default {
    name: 'DemoPlayer',
    props: {
        width: {
            type: Number,
            default: 830,
        },
        height: {
            type: Number,
            default: 770,
        },
        videoUrl: {
            type: String,
            default: () => '',
        },
        whetherPause: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            jessibuca: null,
            wasm: false,
            vc: 'ff',
            playing: false,
            quieting: true,
            loaded: false, // mute
            showOperateBtns: true,
            showBandwidth: false,
            err: '',
            speed: 0,
            performance: '',
            volume: 1,
            rotate: 0,
            vod: false,
            forceNoOffscreen: true,
        }
    },
    mounted() {
        this.create()
        this.play()
        //     .then(() => {
        //     if (this.whetherPause) {
        //         setTimeout(() => {
        //             this.pause()
        //         }, 1000)
        //     }
        // })

    },
    destroyed() {
        this.jessibuca.destroy()
    },
    methods: {
        create() {
            this.jessibuca = new window.Jessibuca({
                container: this.$refs.container,
                isResize: false,
                text: '',
                // background: "bg.jpg",
                loadingText: '加载中',
                // hasAudio:false,
                debug: true,
                showBandwidth: this.showBandwidth, // 显示网速
                supportDblclickFullscreen: true, // 支持双击全屏
                operateBtns: {
                    fullscreen: this.showOperateBtns,
                    screenshot: this.showOperateBtns,
                    play: this.showOperateBtns,
                    audio: this.showOperateBtns,
                },
                vod: this.vod,
                forceNoOffscreen: this.forceNoOffscreen,
                isNotMute: false,
            })

            var _this = this
            this.jessibuca.on('load', function () {
                console.log('on load')

            })

            this.jessibuca.on('log', function (msg) {
                console.log('on log', msg)
            })
            this.jessibuca.on('record', function (msg) {
                console.log('on record:', msg)
            })
            this.jessibuca.on('pause', function () {
                console.log('on pause')
                _this.playing = false
            })
            this.jessibuca.on('play', function () {
                console.log('on play')
                _this.playing = true
            })
            this.jessibuca.on('fullscreen', function (msg) {
                console.log('on fullscreen', msg)
            })

            this.jessibuca.on('mute', function (msg) {
                console.log('on mute', msg)
                _this.quieting = msg
            })

            this.jessibuca.on('audioInfo', function (msg) {
                console.log('audioInfo', msg)
            })

            // this.jessibuca.on("bps", function (bps) {
            //   // console.log('bps', bps);
            // });
            // let _ts = 0;
            // this.jessibuca.on("timeUpdate", function (ts) {
            //   // console.log('timeUpdate,old,new,timestamp', _ts, ts, ts - _ts);
            //   // _ts = ts;
            // });

            this.jessibuca.on('videoInfo', function (info) {
                console.log('videoInfo', info)
            })

            this.jessibuca.on('error', function (error) {
                console.log('error', error)
            })

            this.jessibuca.on('timeout', function () {
                console.log('timeout')
                _this.$emit('Restart');
            })

            this.jessibuca.on('start', function () {
                console.log('start')
            })

            // this.jessibuca.on("stats", function (stats) {
            //   console.log('stats', JSON.stringify(stats));
            // });

            this.jessibuca.on('performance', function (performance) {
                var show = '卡顿'
                if (performance === 2) {
                    show = '非常流畅'
                } else if (performance === 1) {
                    show = '流畅'
                }
                _this.performance = show
            })
            // this.jessibuca.on('buffer', function(buffer) {
            //   console.log('buffer', buffer)
            // })

            // this.jessibuca.on('stats', function(stats) {
            //   console.log('stats', stats)
            // })

            // this.jessibuca.on('kBps', function(kBps) {
            //   console.log('kBps', kBps)
            // })

            // 显示时间戳 PTS
            this.jessibuca.on('videoFrame', function () { })

            //
            this.jessibuca.on('metadata', function () { })

            // console.log(this.jessibuca);
        },
        play() {
            // this.jessibuca.onPlay = () => (this.playing = true);
            this.jessibuca.on('play', () => {
                this.playing = true
                this.loaded = true
                this.quieting = this.jessibuca.quieting
            })

            if (this.videoUrl) {
                if (this.jessibuca.hasLoaded()) {
                    this.jessibuca.play(this.videoUrl)
                } else {
                    this.jessibuca.on('load', () => {
                        this.jessibuca.play(this.videoUrl)
                    })
                }
            }
            return new Promise((resolve) => {
                // 执行第一个方法的逻辑
                // ...
                resolve();
            });
        },
        mute() {
            this.jessibuca.mute()
        },
        cancelMute() {
            this.jessibuca.cancelMute()
        },

        pause() {
            this.jessibuca.pause()
            this.playing = false
            this.err = ''
            this.performance = ''
        },
        volumeChange() {
            this.jessibuca.setVolume(this.volume)
        },
        rotateChange() {
            this.jessibuca.setRotate(this.rotate)
        },
        destroy() {
            if (this.jessibuca) {
                this.jessibuca.destroy()
            }
            this.create()
            this.playing = false
            this.loaded = false
            this.performance = ''
        },

        fullscreen() {
            this.jessibuca.setFullscreen(true)
        },

        screenShot() {
            this.jessibuca.screenshot()
        },

        changeWasm() {
            this.wasm = this.$refs.wasm.checked
        },

        restartPlay() {
            this.destroy()
            this.play()
        },

        changeBuffer() {
            this.jessibuca.setBufferTime(Number(this.$refs.buffer.value))
        },

        changeResize() {
            const value = this.$refs.resize.checked ? 1 : 0
            this.jessibuca.setScaleMode(value)
        },
        changeVod() {
            const value = this.$refs.vod.checked ? 1 : 0
            this.jessibuca.setVod(value)
        },
        changeOffscreen() {
            const value = this.$refs.offscreen.checked ? 1 : 0
            this.jessibuca.setNoOffscreen(value)
        },
    },
}
</script>
<style>
#container {
    background: rgba(13, 14, 27, 0.7);
    width: 640px;
    height: 398px;
}
</style>
