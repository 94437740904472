import * as echarts from "echarts";
import { nextTick } from "vue";

const getMyChart = (el) => {
  let myChart = echarts.getInstanceByDom(el);
  if (myChart == null) {
    myChart = echarts.init(el);
  }
  return myChart;
};

export function createEChart(
  el,
  options,
  resizeFn = () => {
    return {
      width: el.clientWidth,
      height: el.clientHeight,
    };
  },
  upload = true
) {
  const chartObj = getMyChart(el);
  if (upload) {
    chartObj.showLoading({
      text: "加载",
      fontSize: 12,
      color: "#13D4EA",
      textColor: "#13D4EA",
      maskColor: "rgba(255, 255, 255, 0)",
      zlevel: 0,
    });
  }

  const onResize = () => {
    const wh = resizeFn();
    if (wh.width > 0 && wh.height > 0) {
      chartObj.resize({
        width: wh.width,
        height: wh.height,
      });
    }
  };

  setTimeout(() => {
    chartObj.hideLoading();
    chartObj.setOption(options);
  }, 1000);
  nextTick(() => {
    onResize();
  });

  window.addEventListener("unload", () => {
    window.removeEventListener("resize", onResize);
    chartObj.dispose();
  });

  window.addEventListener("unload", () => {
    window.removeEventListener("resize", onResize);
    chartObj.dispose();
  });
}

// 水质监测
export function getOptionsBar(xAxis, yAxis) {
  return {
    xAxis: {
      show: false,
      data: xAxis,
    },
    yAxis: {
      show: false,
      type: "value",
    },
    tooltip: {
      trigger: "axis",
    },
    grid: {
      left: "-10px",
      right: "-10px",
      bottom: "60%",
      containLabel: false,
    },
    series: [
      {
        data: yAxis,
        type: "line",
        smooth: true,
        itemStyle: {
          borderColor: "#74F6F1",
          borderWidth: 2,
        },
        areaStyle: {
          //区域渐变色
          color: new echarts.graphic.LinearGradient(0, 0, 0, 0.9, [
            {
              offset: 1,
              color: "rgba(170, 201 ,234,0)",
            },
            {
              offset: 0,
              color: "rgba(170, 201 ,234,0.3)",
            },
          ]),
        },
        lineStyle: {
          shadowColor: "rgba(170, 201 ,234,0.5)",
          shadowOffsetX: 0,
          shadowOffsetY: 9,
          shadowBlur: 10,
          color: new echarts.graphic.LinearGradient(0, 0, 1, 0, [
            { offset: 0, color: "#28BAEA" }, //线条渐变色
            { offset: 0.2, color: "#16D8CD" },
            { offset: 0.1, color: "#08D39F" },
          ]),
        },
      },
    ],
  };
}

export function getLineChart(xAxis, yAxis, other) {
  return new Promise((resolve) => {
    const options = getOptionsBar(xAxis, yAxis, other);
    resolve(options);
  });
}

// 进度条
export function getOptions(xAxis, yAxis, unit) {
  const colors = [
    "#00C0FA",
    "#FFEF68",
    "#CF080C",
    "#00FFBA",
    "#92B2FF",
    "#B17B1D",
    "#987EFF",
    "#007FC0",
    "#00ff48",
    "#ffa200",
  ];
  var maxNum = 0;
  for (var i = 0; i < xAxis.length; i++) {
    if (xAxis[i] > maxNum) {
      maxNum = xAxis[i];
    }
  }
  return {
    grid: {
      top: "5%",
      left: "5%",
      right: "15%",
      bottom: "0",
    },
    xAxis: {
      show: false,
      type: "value",
    },
    yAxis: {
      type: "category",
      align: "left",
      padding: [0, 0, 40, 10],
      axisLabel: {
        color: "#FFF",
        fontSize: 14,
        width: 80,
        padding: [0, 0, 50, 8],
        align: "left",
        formatter: (params) => {
          return `${params}${unit ? "(" + unit + ")" : ""}`;
        },
        rich: {
          value: {
            color: "#fff",
            fontSize: 12,
            fontWeight: 500,
          },
        },
      },
      axisTick: "none",
      inverse: true,
      axisLine: {
        show: false,
      },
      data: yAxis,
    },
    series: [
      {
        type: "pictorialBar",
        barWidth: 8,
        legendHoverLink: false,
        silent: true,
        symbolRepeat: true,
        symbolMargin: 2,
        symbol: "rect",
        symbolClip: true,
        symbolSize: [3, 10],
        symbolPosition: "start",
        itemStyle: {
          color: function (params) {
            return colors[params.dataIndex];
          },
        },
        data: xAxis,
      },
      {
        name: "背景条",
        type: "bar",
        silent: true,
        barWidth: 10,
        barGap: "-100%",
        itemStyle: {
          color: "#123043",
        },
        label: {
          show: true,
          position: "right",
          fontSize: 14,
          padding: [0, 0, 0, 15],
          color: "#FFF",
          formatter: function (params) {
            return params.data.realValue;
          },
        },
        data: xAxis.map(function (item) {
          return {
            realValue: item,
            value: maxNum * 2,
          };
        }),
        z: 0,
      },
    ],
  };
}

export function getBarChart(xAxis, yAxis, other) {
  return new Promise((resolve) => {
    const options = getOptions(xAxis, yAxis, other);
    resolve(options);
  });
}

//柱状图
export function getOptionsGradient(xAxis, yAxis, other) {
  return {
    grid: {
      top: "18%",
      bottom: "15%",
    },
    tooltip: {
      trigger: "item",
      formatter: function (params) {
        return (
          '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:#ffcf20;"></span>' +
          "&nbsp" +
          params.name +
          ":" +
          "&nbsp" +
          "&nbsp" +
          params.value
        );
      },
    },
    animation: true,
    xAxis: [
      {
        type: "category",
        data: xAxis,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          interval: 0,
        },
        nameTextStyle: {
          color: "#D1E5FF",
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "#D1E5FF",
          },
        },
      },
    ],

    yAxis: [
      {
        name: other,
        nameGap: "10",
        nameTextStyle: {
          color: "#D1E5FF",
          padding: [10, 20, 5, 0],
        },
        show: true,
        type: "value",
        axisLabel: {
          formatter: `{value}`,
          textStyle: {
            color: "#D1E5FF",
          },
        },
        splitLine: {
          lineStyle: {
            color: "rgba(255,255,255,0.1)",
            type: "dashed",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "rgba(255,255,255,0.1)",
          },
        },
      },
    ],
    series: [
      {
        name: "下椭圆",
        type: "pictorialBar",
        symbolSize: [22, 10],
        symbolOffset: [0, 4],
        z: 12,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1,
            [
              {
                offset: 0,
                color: "rgba(21, 174, 242,0)",
              },
              {
                offset: 1,
                color: "rgba(56,113,144,1)",
              },
            ],
            false
          ),
        },
        data: yAxis,
      },
      {
        type: "bar",
        barWidth: "8",
        barGap: "5%",
        barCateGoryGap: "5%",
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
              {
                offset: 1,
                color: "rgba(0, 255, 234, 0.8)",
              },
              {
                offset: 0.5,
                color: "rgba(76, 149, 239, 0.5)",
              },
              {
                offset: 0,
                color: "rgba(55, 170, 230, 0)",
              },
            ]),
          },
        },
        data: yAxis,
        label: {
          show: true, //是否显示
          position: "top", // 顶部显示
          formatter: "{c}", // 显示数据值
          color: "#fff", // 标签颜色
          // 其他样式属性，如字体大小、格式等
          fontSize: 16,
        },
      },
    ],
  };
}

export function geGradientBarChart(xAxis, yAxis, other) {
  return new Promise((resolve) => {
    const options = getOptionsGradient(xAxis, yAxis, other);
    resolve(options);
  });
}

//雷达图
export function geGradientRadar(xAxis, yAxis) {
  return {
    //配置维度的最大值
    radar: {
      center: ["50%", "50%"],
      radius: "60%",
      splitNumber: 4,
      shape: "circle", //对雷达图形设置成一个圆形,可选 circle:圆形,polygon:多角形(默认)
      name: {
        show: true,
        color: "#ffffff",
      },
      splitArea: {
        // 'show': false
        areaStyle: {
          color: ["#161F30", "#161F30", "#161F30", "#161F30"], // 每个圆的背景颜色
          shadowColor: "#161F30", // 每个圈的阴影颜色
          shadowBlur: 10,
        },
      },

      // 设置隐藏蜘蛛网
      splitLine: {
        lineStyle: {
          color: "#284255", // 每个圈的边框颜色
        },
      },
      axisLine: {
        lineStyle: {
          color: "#4C7092",
        },
      },
      //   雷达图的指示器，用来指定雷达图中的多个变量（维度）
      indicator: xAxis,
    },
    series: [
      {
        type: "radar",
        label: {
          show: false, //显示数值
        },
        areaStyle: {}, //每个雷达图形成一个阴影的面积，
        data: [
          {
            name: "",
            value: yAxis,
            areaStyle: {
              normal: {
                color: "#173c48", // 选择区域颜色
              },
            },
            lineStyle: {
              color: "#62FDFB", // 雷达构成的区域边框
            },
          },
        ],
      },
    ],
  };
}

export function geGradientRadarChart(xAxis, yAxis, other) {
  return new Promise((resolve) => {
    const options = geGradientRadar(xAxis, yAxis, other);
    resolve(options);
  });
}

//折线图
export function getOptionsLine(xAxis, yAxis, other) {
  return {
    title: {
      // text: this.text,
      left: "left",
      top: "0%",
      textStyle: {
        //图例文字的样式
        color: "#fff",
        fontSize: 15,
      },
    },
    color: ["#14BDD9"],

    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "cross",
        label: {
          backgroundColor: "#283b56",
        },
      },
    },

    grid: {
      width: "95%",
      height: "90%",
      left: "0",
      top: "15",
      button: "0",
      containLabel: true,
      showBackground: true,
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
    },
    // 横坐标数据
    xAxis: {
      data: xAxis,
      boundaryGap: false,
      axisLabel: {
        color: "#fff",
      },
      axisTick: {
        show: true,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#202951",
          width: 1,
        },
      },
      z: 10,
      offset: 10,
    },
    // 纵坐标数据
    yAxis: {
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          color: "#202951",
          width: 1,
        },
      },
      axisLabel: {
        color: "#ccc",
        formatter: `{value} ${other}`,
      },
    },
    dataZoom: [
      {
        type: "inside",
      },
    ],
    barGap: 0,
    series: [
      {
        symbol: "none",
        itemStyle: {},
        areaStyle: {
          color: new echarts.graphic.LinearGradient(0, 1, 0, 0, [
            {
              offset: 0,
              color: "rgba(8, 11, 26, 0)",
            },
            {
              offset: 1,
              color: "rgba(0, 255, 255, 1)",
            },
          ]),
        },
        type: "line",
        calculable: true,
        barWidth: "20", //---柱形宽度
        barCategoryGap: "10%",
        barGap: 0,
        data: yAxis,
        smooth: true,
      },
    ],
  };
}

export function geGradientLineChart(xAxis, yAxis, other) {
  return new Promise((resolve) => {
    const options = getOptionsLine(xAxis, yAxis, other);
    resolve(options);
  });
}

//柱状+折线图
export function getOptionsLineDient(xAxis, yAxis, other) {
  return {
    title: {
      text: other,
      left: "left",
      top: "0%",
      textStyle: {
        //图例文字的样式
        color: "#fff",
        fontSize: 15,
      },
    },
    color: ["#14BDD9"],

    tooltip: {
      trigger: "item",
      show: true,
      axisPointer: {
        type: "shadow",
        axis: "auto",
      },
    },

    grid: {
      width: "90%",
      height: "80%",
      left: "5",
      top: "15%",
      button: "0",
      containLabel: true,
      showBackground: true,
    },
    toolbox: {
      show: true,
      orient: "vertical",
      left: "right",
      top: "center",
    },
    // 横坐标数据
    xAxis: {
      data: xAxis,
      boundaryGap: false,
      axisLabel: {
        color: "#fff",
      },
      axisTick: {
        show: true,
      },
      axisLine: {
        show: true,
        lineStyle: {
          color: "#202951",
          width: 1,
        },
      },
      z: 10,
      offset: 10,
    },
    // 纵坐标数据
    yAxis: {
      type: "value",
      splitLine: {
        show: true,
        lineStyle: {
          color: "#202951",
          width: 1,
        },
      },
      axisLabel: {
        color: "#ccc",
        formatter: `{value}`,
        margin: 25,
      },
    },
    dataZoom: [
      {
        type: "inside",
      },
    ],
    barGap: 0,
    series: [
      {
        symbol: "circle",
        itemStyle: {},
        type: "line",
        calculable: true,
        barWidth: "20", //---柱形宽度
        barCategoryGap: "10%",
        barGap: 0,
        data: yAxis,
        smooth: true,
      },
      {
        name: "下椭圆",
        type: "pictorialBar",
        symbolSize: [22, 10],
        symbolOffset: [0, 4],
        z: 12,
        itemStyle: {
          color: new echarts.graphic.LinearGradient(
            0,
            0,
            0,
            1,
            [
              {
                offset: 0,
                color: "rgba(21, 174, 242,0)",
              },
              {
                offset: 1,
                color: "rgba(56,113,144,1)",
              },
            ],
            false
          ),
        },
        data: yAxis,
      },
      {
        type: "bar",
        barWidth: "8",
        barGap: "5%",
        barCateGoryGap: "5%",
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 0.7, [
              {
                offset: 1,
                color: "rgba(0, 255, 234, 0.8)",
              },
              {
                offset: 0.5,
                color: "rgba(76, 149, 239, 0.5)",
              },
              {
                offset: 0,
                color: "rgba(55, 170, 230, 0)",
              },
            ]),
          },
        },
        data: yAxis,
        label: {
          show: true, //是否显示
          position: "top", // 顶部显示
          formatter: "{c}", // 显示数据值
          color: "#fff", // 标签颜色
          // 其他样式属性，如字体大小、格式等
          fontSize: 16,
        },
      },
    ],
  };
}

export function geGradientLineDientzz(xAxis, yAxis, other) {
  return new Promise((resolve) => {
    const options = getOptionsLineDient(xAxis, yAxis, other);
    resolve(options);
  });
}

//仪表盘
export function getOptionsLineDientInstruments(Axis, max, other) {
  return {
    tooltip: {
      formatter: "{a} <br/>{b} : {c}%",
    },
    series: [
      {
        name: "Pressure",
        type: "gauge",
        max: max, // 最大的数据值,默认 100 。映射到 maxAngle。
        // progress: {
        //   show: true,
        // },
        detail: {
          valueAnimation: true,
          // 仪表盘详情，用于显示数据。(仪表盘的数值字体样式和显示位置)
          show: true, // 是否显示详情,默认 true。
          offsetCenter: [0, "50%"], // 相对于仪表盘中心的偏移位置，数组第一项是水平方向的偏移，第二项是垂直方向的偏移。可以是绝对的数值，也可以是相对于仪表盘半径的百分比。
          color: "inherit", // 文字的颜色,默认 auto。
          fontSize: 16, // 文字的字体大小,默认 15。
          formatter: `{value}${other}`, // 格式化函数或者字符串
        },
        axisLabel: {
          show: false, // 不显示刻度值
        },
        axisLine: {
          // 仪表盘轴线样式
          lineStyle: {
            width: 3,
            color: [
              [0.3, "#00EAFF"],
              [1, "#00EAFF"],
              // [1, "#FD2323"],
            ],
          },
        },
        axisTick: {
          show: false,
        },
        splitLine: {
          // 分割线样式
          length: 8,
          lineStyle: {
            color: "auto",
            width: 1,
          },
        },
        pointer: {
          // 仪表盘指针。
          show: true, // 是否显示指针,默认 true。
          length: "40%", // 指针长度，可以是绝对数值，也可以是相对于半径的百分比,默认 80%。
          width: 2, // 指针宽度,默认 8。
        },
        itemStyle: {
          // 仪表盘指针样式。
          color: "inherit", // 指针颜色，默认(auto)取数值所在的区间的颜色
        },
        data: [
          {
            // value: 80,
            // name: "SCORE",
            ...Axis,
            title: {
              offsetCenter: ["0%", "100%"],
              color: "inherit", // 文字的颜色,默认 #333。
              fontSize: 14, // 文字的字体大小,默认 15。
            },
          },
        ],
      },
    ],
  };
}

export function geGradientLineDientInstruments(Axis, max, other) {
  return new Promise((resolve) => {
    const options = getOptionsLineDientInstruments(Axis, max, other);
    resolve(options);
  });
}

//立体柱状图
export function getOptionsStereoscopic(xAxis, yAxis, other) {
  return {
    xAxis: [
      {
        type: "category",
        data: xAxis,
        axisTick: {
          alignWithLabel: true,
        },
        axisLabel: {
          interval: 0,
        },
        nameTextStyle: {
          color: "#D1E5FF",
        },
        axisLine: {
          show: false,
          lineStyle: {
            color: "#D1E5FF",
          },
        },
      },
    ],
    yAxis: [
      {
        name: other,
        nameGap: "10",
        nameTextStyle: {
          color: "#D1E5FF",
          padding: [10, 20, 5, 0],
        },
        show: true,
        type: "value",
        axisLabel: {
          formatter: `{value}`,
          textStyle: {
            color: "#D1E5FF",
          },
        },
        splitLine: {
          lineStyle: {
            color: "rgba(255,255,255,0.1)",
            type: "dashed",
          },
        },
        axisLine: {
          show: true,
          lineStyle: {
            color: "rgba(255,255,255,0.1)",
          },
        },
      },
    ],
    grid: {
      width: "90%",
      height: "80%",
      left: "5%",
      top: "15%",
      button: "0",
      containLabel: true,
      showBackground: true,
    },
    tooltip: {
      trigger: "axis",
    },
    // legend: {
    //   top: 0,
    //   right: 10,
    //   selectedMode: false,
    //   data: [
    //     {
    //       name: "已处理",
    //       textStyle: {
    //         color: "white",
    //       },
    //     },
    //     {
    //       name: "未处理",
    //       textStyle: {
    //         color: "white",
    //       },
    //     },
    //   ],
    // },
    series: [
      {
        name: "已处理",
        tooltip: {
          show: false,
        },
        type: "bar",
        barWidth: 10,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              1,
              0,
              0,
              [
                {
                  offset: 0.2,
                  color: "RGBA(20, 207, 233, 0.2)", // 0% 处的颜色
                },
                {
                  offset: 0.6,
                  color: "RGBA(20, 207, 233, 0.6)", // 60% 处的颜色
                },
                {
                  offset: 1,
                  color: "RGBA(20, 207, 233, 1)", // 100% 处的颜色
                },
              ],
              false
            ),
          },
        },
        data: yAxis[0],
        barGap: 0,
      },
      {
        name: "已处理",
        type: "bar",
        barWidth: 10,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              1,
              0,
              0,
              [
                {
                  offset: 0.2,
                  color: "RGBA(8, 177, 132, 0.2)", // 0% 处的颜色
                },
                {
                  offset: 0.6,
                  color: "RGBA(8, 177, 132, 0.6)", // 60% 处的颜色
                },
                {
                  offset: 1,
                  color: "RGBA(8, 177, 132, 1)", // 100% 处的颜色
                },
              ],
              false
            ),
          },
        },
        barGap: 0,
        data: yAxis[0],
        label: {
          show: false,
          position: "top",
          textStyle: {
            color: "white",
            fontSize: 10,
          },
        },
      },
      {
        name: "已处理",
        tooltip: {
          show: false,
        },
        type: "pictorialBar",
        itemStyle: {
          borderWidth: 1,
          borderColor: "#0571D5",
          color: "RGBA(8, 177, 132, 1)", // 控制顶部方形的颜色
        },
        symbol: "path://M 0,0 l 90,0 l -60,60 l -90,0 z",
        symbolSize: ["20", "7"], // 第一个值控制顶部方形大小
        symbolOffset: ["-13", "-4"], // 控制顶部放行 左右和上下
        symbolRotate: -16,
        symbolPosition: "end",
        data: yAxis[0],
        z: 3,
      },
      {
        name: "已处理",
        type: "bar",
        barWidth: 7,
        tooltip: {
          show: false,
        },
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              1,
              0,
              0,
              [
                {
                  offset: 0,
                  color: "rgba(1, 6, 38, 0)", // 0% 处的颜色
                },
                {
                  offset: 1,
                  color: "rgba(1, 6, 38, 0)", // 100% 处的颜色
                  //opacity:0.5
                },
              ],
              false
            ),
          },
        },
        barGap: 0,
        data: yAxis[0],
        label: {
          show: false,
          position: "top",
          textStyle: {
            color: "white",
            fontSize: 10,
          },
        },
      },
      {
        name: "未处理",
        tooltip: {
          show: false,
        },
        type: "bar",
        barWidth: 10,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              1,
              0,
              0,
              [
                {
                  offset: 0.2,
                  color: "RGBA(240, 161, 22, 0.2)", // 0% 处的颜色
                },
                {
                  offset: 0.6,
                  color: "RGBA(240, 161, 22, 0.6)", // 60% 处的颜色
                },
                {
                  offset: 1,
                  color: "RGBA(240, 161, 22, 1)", // 100% 处的颜色
                },
              ],
              false
            ),
          },
        },
        data: yAxis[1],
        barGap: 0,
      },
      {
        name: "未处理",
        type: "bar",
        barWidth: 10,
        itemStyle: {
          normal: {
            color: new echarts.graphic.LinearGradient(
              0,
              1,
              0,
              0,
              [
                {
                  offset: 0.2,
                  color: "RGBA(164, 121, 21, 0.2)", // 0% 处的颜色
                },
                {
                  offset: 0.6,
                  color: "RGBA(164, 121, 21, 0.6)", // 60% 处的颜色
                },
                {
                  offset: 1,
                  color: "RGBA(164, 121, 21, 1)", // 100% 处的颜色
                },
              ],
              false
            ),
          },
        },
        barGap: 0,
        data: yAxis[1],
        label: {
          show: false,
          position: "top",
          textStyle: {
            color: "white",
            fontSize: 10,
          },
        },
      },
      {
        name: "未处理",
        tooltip: {
          show: false,
        },
        type: "pictorialBar",
        itemStyle: {
          borderWidth: 1,
          borderColor: "#fff",
          color: "RGBA(164, 121, 21, 1)", // 顶部方块的颜色
        },
        symbol: "path://M 0,0 l 90,0 l -60,60 l -90,0 z",
        symbolSize: ["20", "7"], // 第一个值控制顶部方形大小
        symbolOffset: ["13", "-4"], // 控制顶部放行 左右和上下
        symbolRotate: -16,
        symbolPosition: "end",
        data: yAxis[1],
        z: 3,
      },
    ],
  };
}

export function Stereoscopic(xAxis, yAxis, other) {
  return new Promise((resolve) => {
    const options = getOptionsStereoscopic(xAxis, yAxis, other);
    resolve(options);
  });
}
