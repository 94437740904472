import camera from "@/images/camera.png";
import water from "@/images/water-monitor.png";
import water2 from "@/images/water-quality-sensor.png";
import dtb from "@/images/dtb.png";
import rain from "@/images/rain-monitor.png";
import CS from "@/images/cs.png";
import HS from "@/images/hs.png";
import LJ from "@/images/lj.png";
import TR from "@/images/tr.png";
import DM from "@/images/duanmian.png";
//router 流域概况：'', 环境监测：pipe, 产业发展：agro，社会共治：Social,预警分析：early
export const pointArr = [
  // {
  //   lnglat: [110.844355, 32.981025],
  //   name: "龙泉村2组华林酒坊路边",
  //   point: "龙泉村2组华林酒坊监测点位",
  //   equipment: "雨量监测设备",
  //   type: rain,
  //   source: 1,
  //   params: "",
  //   router: ["pipe"],
  //   genre: "岸线实时监控点",
  // },
  {
    lnglat: [110.8402, 32.955],
    name: "谭家湾镇",
    point: "谭家湾镇河流监测3号点位",
    equipment: "水质传感器",
    type: water,
    source: 2,
    params: "7846486",
    router: ["pipe", "early"],
    genre: "水质监测",
  },
  {
    lnglat: [110.832117, 32.917632],
    name: "谭家湾镇",
    point: "谭家湾镇河流监测5号点位",
    equipment: "水质传感器",
    type: water,
    source: 2,
    params: "7846484",
    router: ["pipe", "early"],
    genre: "水质监测",
  },
  {
    lnglat: [110.83080367, 32.92551617],
    name: "谭家湾镇",
    point: "水质监测站(黄畈村)",
    equipment: "水质监测站",
    type: water2,
    source: 2,
    params: "4203041152111",
    router: ["pipe", "early"],
    genre: "水质监测",
  },
  {
    lnglat: [110.852428,32.921215],
    name: "谭家湾镇",
    point: "五道岭水质监测站",
    equipment: "水质监测站",
    type: water2,
    source: 2,
    params: "4203041152112",
    router: ["pipe", "early"],
    genre: "水质监测",
  },
  {
    lnglat: [110.82682, 32.83385],
    name: "棒槌河大桥",
    point: "棒槌河大桥",
    equipment: "断面监测",
    type: DM,
    source: 2,
    params: "test001",
    router: ["pipe"],
    genre: "断面监测",
    title: "断面水质监测",
  },
  {
    lnglat: [110.8392, 32.955],
    name: "龙泉村2组华林酒坊路边",
    point: "谭家湾镇河流监测4号点位",
    equipment: "摄像头",
    type: camera,
    source: 0,
    params: "yyxly04",
    router: ["pipe"],
    genre: "岸线实时监控点",
  },
  {
    lnglat: [110.8463, 32.9506],
    name: "谭家湾镇龙泉村老村部后山",
    point: "谭家湾镇龙泉村森林防火点",
    equipment: "森林防火监控摄像头",
    type: camera,
    source: 0,
    params: "yyxly12",
    router: ["pipe"],
    genre: "森林防火监测点",
  },
  {
    lnglat: [110.8412, 32.9671],
    name: "谭家湾镇龙泉村新村部后山",
    point: "谭家湾镇龙泉村森林防火点",
    equipment: "森林防火监控摄像头",
    type: camera,
    source: 0,
    params: "yyxly11",
    router: ["pipe"],
    genre: "森林防火监测点",
  },
  {
    lnglat: [110.8434, 32.9699],
    name: "龙泉村4组39号路对面",
    point: "谭家湾镇河流监测2号点位",
    equipment: "摄像头",
    type: camera,
    source: 0,
    // yyxly09、yyxly10
    params: "yyxly09",
    router: ["pipe"],
    genre: "岸线实时监控点",
  },
  {
    lnglat: [110.8332, 32.9285],
    name: "黄畈村4组48号路对面",
    point: "谭家湾镇河流监测4号点位",
    equipment: "摄像头",
    type: camera,
    source: 0,
    params: "yyxly03",
    router: ["pipe"],
    genre: "岸线实时监控点",
  },
  {
    lnglat: [110.8484, 32.9837],
    name: "龙泉村取水口",
    point: "谭家湾镇河流监测0号点位水源地",
    equipment: "水源监测摄像头",
    type: camera,
    source: 0,
    params: "yyxly15",
    router: ["pipe"],
    genre: "岸线实时监控点",
  },
  {
    lnglat: [110.8478, 32.9749],
    name: "龙泉村4组12号路对面",
    point: "谭家湾镇河流监测1号点位水源地",
    equipment: "摄像头",
    type: camera,
    source: 0,
    // yyxly07、yyxly08
    params: "yyxly07",
    router: ["pipe"],
    genre: "岸线实时监控点",
  },
  {
    lnglat: [110.8408, 32.9662],
    name: "谭家湾镇龙泉村3组阿和人家农家乐背后",
    point: "谭家湾镇龙泉村滑坡体",
    equipment: "摄像头",
    type: camera,
    source: 0,
    params: "yyxly13",
    router: ["pipe"],
    genre: "滑坡监测点",
  },
  {
    lnglat: [110.830907, 32.917632],
    name: "黄畈村5组65号路对面",
    point: "谭家湾镇河流监测5号点位",
    equipment: "摄像头",
    type: camera,
    source: 0,
    params: "yyxly02",
    router: ["pipe"],
    genre: "岸线实时监控点",
  },
  {
    lnglat: [110.834795, 32.962977],
    name: "龙泉村葡萄餐厅大棚",
    point: "龙泉村葡萄餐厅大棚",
    equipment: "大棚监测",
    type: dtb,
    source: 3,
    params: "0",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.835795, 32.962577],
    name: "龙泉村葡萄餐厅土壤墒情1",
    point: "龙泉村葡萄餐厅土壤墒情1",
    equipment: "土壤墒情",
    type: TR,
    source: 2,
    params: "2024051400110001",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.835972, 32.964848],
    name: "龙泉村葡萄餐厅土壤墒情2",
    point: "龙泉村葡萄餐厅土壤墒情2",
    equipment: "土壤墒情",
    type: TR,
    source: 2,
    params: "2024051400110002",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.835872, 32.964098],
    name: "龙泉村葡萄餐厅土壤墒情3",
    point: "龙泉村葡萄餐厅土壤墒情3",
    equipment: "土壤墒情",
    type: TR,
    source: 2,
    params: "2024051400110003",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.835798, 32.963256],
    name: "龙泉村葡萄餐厅土壤墒情4",
    point: "龙泉村葡萄餐厅土壤墒情4",
    equipment: "土壤墒情",
    type: TR,
    source: 2,
    params: "2024051400110004",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.834595, 32.964091],
    name: "龙泉村葡萄餐厅土壤墒情5",
    point: "龙泉村葡萄餐厅土壤墒情5",
    equipment: "土壤墒情",
    type: TR,
    source: 2,
    params: "2024051400110005",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.84978, 32.922156],
    name: "五道岭大棚（坤田农业）",
    point: "龙泉村坤田农业",
    equipment: "大棚监测",
    type: dtb,
    source: 3,
    params: "1",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.844973, 32.91716],
    name: "五道岭香菇大棚左1",
    point: "五道岭香菇大棚左1",
    equipment: "摄像头",
    type: camera,
    source: 0,
    params: "yyxly22",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.849846, 32.919594],
    name: "五道岭香菇大棚左2",
    point: "五道岭香菇大棚左2",
    equipment: "摄像头",
    type: camera,
    source: 0,
    params: "yyxly23",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.854865, 32.922641],
    name: "五道岭香菇大棚右1",
    point: "五道岭香菇大棚右1",
    equipment: "摄像头",
    type: camera,
    source: 0,
    params: "yyxly24",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.86349, 32.924379],
    name: "五道岭香菇大棚右2",
    point: "五道岭香菇大棚右2",
    equipment: "摄像头",
    type: camera,
    source: 0,
    params: "yyxly25",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.840121, 32.867446],
    name: "菌棒原料加工车间",
    point: "菌棒原料加工车间",
    equipment: "摄像头",
    type: camera,
    source: 0,
    params: "yyxly18",
    router: ["agro"],
    genre: "",
  },
  // {
  //   lnglat: [110.841029, 32.863304],
  //   name: "珍稀菌产业中心装袋生产线",
  //   point: "珍稀菌产业中心装袋生产线",
  //   equipment: "摄像头",
  //   type: camera,
  //   source: 0,
  //   params: "yyxly19",
  //   router: ["agro"],
  //   genre: "",
  // },
  // {
  //   lnglat: [110.841029, 32.863004],
  //   name: "珍稀菌产业中心装袋生产线",
  //   point: "珍稀菌产业中心装袋生产线",
  //   equipment: "摄像头",
  //   type: camera,
  //   source: 0,
  //   params: "yyxly20",
  //   router: ["agro"],
  //   genre: "",
  // },
  {
    lnglat: [110.841029, 32.863604],
    name: "菌棒袋料生产车间",
    point: "菌棒袋料生产车间",
    equipment: "摄像头",
    type: camera,
    source: 0,
    params: "yyxly21",
    router: ["agro"],
    genre: "",
  },
  {
    lnglat: [110.83240291, 32.92555594],
    name: "厕所改造（肖廷有）",
    point: "厕所改造（肖廷有）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8321097, 32.9259025],
    name: "厕所改造（康树六）",
    point: "厕所改造（康树六）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83192238, 32.9261125],
    name: "厕所改造（4组）",
    point: "厕所改造（4组）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83132041, 32.92746978],
    name: "厕所改造（村部公厕）",
    point: "厕所改造（村部公厕）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83172313, 32.92638471],
    name: "厕所改造（周吉成）",
    point: "厕所改造（周吉成）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83172313, 32.92638471],
    name: "厕所改造（周吉成）",
    point: "厕所改造（周吉成）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83243463, 32.92636916],
    name: "厕所改造（刘文强）",
    point: "厕所改造（刘文强）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83232116, 32.92674769],
    name: "厕所改造（康书国）",
    point: "厕所改造（康书国）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83242566, 32.92617384],
    name: "厕所改造（蔡炳国）",
    point: "厕所改造（蔡炳国）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83276729, 32.92617023],
    name: "厕所改造（段富学）",
    point: "厕所改造（段富学）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83281573, 32.92650535],
    name: "厕所改造（蔡光东）",
    point: "厕所改造（蔡光东）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83310184, 32.92624924],
    name: "厕所改造（康永）",
    point: "厕所改造（康永）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83269813, 32.92694031],
    name: "厕所改造（肖大明）",
    point: "厕所改造（肖大明）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83239256, 32.92496803],
    name: "厕所改造（肖大林）",
    point: "厕所改造（肖大林）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83192723, 32.92443988],
    name: "厕所改造（肖廷忠）",
    point: "厕所改造（肖廷忠）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83191848, 32.92489397],
    name: "厕所改造（赵廷军）",
    point: "厕所改造（赵廷军）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83201319, 32.92514178],
    name: "厕所改造（高国章）",
    point: "厕所改造（高国章）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83177256, 32.92631043],
    name: "垃圾分类",
    point: "垃圾分类",
    equipment: "垃圾分类",
    type: LJ,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "垃圾分类点",
  },
  {
    lnglat: [110.83177, 32.926345],
    name: "资源回收",
    point: "资源回收",
    equipment: "资源回收",
    type: HS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "垃圾分类点",
  },
  {
    lnglat: [110.83229859, 32.92766378],
    name: "厕所改造（陈明海）",
    point: "厕所改造（陈明海）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83260804, 32.92794862],
    name: "厕所改造（康邦生）",
    point: "厕所改造（康邦生）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83275206, 32.9284833],
    name: "厕所改造（刘会明）",
    point: "厕所改造（刘会明）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83174353, 32.92771568],
    name: "厕所改造（段祖廷）",
    point: "厕所改造（段祖廷）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.831503, 32.926942],
    name: "垃圾分类",
    point: "垃圾分类",
    equipment: "垃圾分类",
    type: LJ,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "垃圾分类点",
  },
  {
    lnglat: [110.82896678, 32.92907479],
    name: "厕所改造（康邦丽）",
    point: "厕所改造（康邦丽）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.82874338, 32.929364],
    name: "厕所改造（刘来学）",
    point: "厕所改造（刘来学）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8283025, 32.92994026],
    name: "厕所改造（王星林）",
    point: "厕所改造（王星林）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.82845324, 32.93021911],
    name: "厕所改造（易安学）",
    point: "厕所改造（易安学）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.82843668, 32.9303114],
    name: "厕所改造（康邦全）",
    point: "厕所改造（康邦全）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.82798831, 32.9302181],
    name: "厕所改造（马德举）",
    point: "厕所改造（马德举）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.82778687, 32.93055913],
    name: "厕所改造（万林）",
    point: "厕所改造（万林）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.82920221, 32.9287803],
    name: "厕所改造（王从芝）",
    point: "厕所改造（王从芝）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8303283, 32.9290483],
    name: "厕所改造（杨洪章）",
    point: "厕所改造（杨洪章）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.829283, 32.912792],
    name: "垃圾分类",
    point: "垃圾分类",
    equipment: "垃圾分类",
    type: LJ,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "垃圾分类点",
  },
  {
    lnglat: [110.834021, 32.907545],
    name: "垃圾分类",
    point: "垃圾分类",
    equipment: "垃圾分类",
    type: LJ,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "垃圾分类点",
  },
  {
    lnglat: [110.8269971, 32.89448379],
    name: "厕所改造（肖大国）",
    point: "厕所改造（肖大国）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8285693, 32.89512815],
    name: "厕所改造（肖大英）",
    point: "厕所改造（肖大英）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.828651, 32.89500624],
    name: "厕所改造（肖俊）",
    point: "厕所改造（肖俊）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8286025, 32.89550223],
    name: "厕所改造（金善华）",
    point: "厕所改造（金善华）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8288119, 32.89669654],
    name: "厕所改造（肖廷奎）",
    point: "厕所改造（肖廷奎）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8285556, 32.89730626],
    name: "厕所改造（肖平）",
    point: "厕所改造（肖平）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8273016, 32.89801562],
    name: "厕所改造（吴首艳）",
    point: "厕所改造（吴首艳）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8286347, 32.8981018],
    name: "厕所改造（王海珍）",
    point: "厕所改造（王海珍）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8334943, 32.90647587],
    name: "厕所改造（邵德国）",
    point: "厕所改造（邵德国）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8333676, 32.90652804],
    name: "厕所改造（肖廷义）",
    point: "厕所改造（肖廷义）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8331524, 32.90632341],
    name: "厕所改造（宁永贵）",
    point: "厕所改造（宁永贵）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8327817, 32.9063187],
    name: "厕所改造（闵兆国）",
    point: "厕所改造（闵兆国）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8331745, 32.9060706],
    name: "厕所改造（陈玉邦）",
    point: "厕所改造（陈玉邦）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8334435, 32.90631458],
    name: "厕所改造（王胜友）",
    point: "厕所改造（王胜友）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8332791, 32.90559861],
    name: "厕所改造（魏新荣）",
    point: "厕所改造（魏新荣）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8335531, 32.90668518],
    name: "厕所改造（张志军）",
    point: "厕所改造（张志军）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.833487, 32.90681459],
    name: "厕所改造（朱志生）",
    point: "厕所改造（朱志生）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8334014, 32.90684895],
    name: "厕所改造（肖廷明）",
    point: "厕所改造（肖廷明）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8329145, 32.90697865],
    name: "厕所改造（王学英）",
    point: "厕所改造（王学英）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8331768, 32.90663419],
    name: "厕所改造（肖波）",
    point: "厕所改造（肖波）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.833736, 32.90712469],
    name: "厕所改造（何少虎）",
    point: "厕所改造（何少虎）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8336965, 32.9071056],
    name: "厕所改造（孙本成）",
    point: "厕所改造（孙本成）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8341262, 32.90802733],
    name: "厕所改造（陈书邦）",
    point: "厕所改造（陈书邦）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8341886, 32.90834772],
    name: "厕所改造（陈六邦）",
    point: "厕所改造（陈六邦）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8342633, 32.90865205],
    name: "厕所改造（黄贵兵）",
    point: "厕所改造（黄贵兵）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8341742, 32.90869009],
    name: "厕所改造（宁永秀）",
    point: "厕所改造（宁永秀）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8372239, 32.91157787],
    name: "厕所改造（刘国兵）",
    point: "厕所改造（刘国兵）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8366839, 32.91094062],
    name: "厕所改造（唐九强）",
    point: "厕所改造（唐九强）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8350652, 32.91027716],
    name: "厕所改造（何廷太）",
    point: "厕所改造（何廷太）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8351565, 32.90979287],
    name: "厕所改造（王四青）",
    point: "厕所改造（王四青）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8352244, 32.90968995],
    name: "厕所改造（朱文林）",
    point: "厕所改造（朱文林）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8294727, 32.91193619],
    name: "厕所改造（王庆德）",
    point: "厕所改造（王庆德）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8295524, 32.91167738],
    name: "厕所改造（王庆林）",
    point: "厕所改造（王庆林）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.829564, 32.9116528],
    name: "厕所改造（王宝）",
    point: "厕所改造（王宝）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8296463, 32.91105086],
    name: "厕所改造（王开红）",
    point: "厕所改造（王开红）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8301075, 32.91076102],
    name: "厕所改造（宋洪兴）",
    point: "厕所改造（宋洪兴）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8304828, 32.9105545],
    name: "厕所改造（涂士玉）",
    point: "厕所改造（涂士玉）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8304491, 32.91056338],
    name: "厕所改造（白金英）",
    point: "厕所改造（白金英）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8306466, 32.91040618],
    name: "厕所改造（陈贤芬）",
    point: "厕所改造（陈贤芬）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8307054, 32.91040836],
    name: "厕所改造（王开贵）",
    point: "厕所改造（王开贵）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.830856, 32.91024128],
    name: "厕所改造（王开喜）",
    point: "厕所改造（王开喜）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8311791, 32.90991208],
    name: "厕所改造（王开升）",
    point: "厕所改造（王开升）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8265961, 32.93173274],
    name: "厕所改造（孙贤文）",
    point: "厕所改造（孙贤文）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8256253, 32.93370452],
    name: "厕所改造（李红）",
    point: "厕所改造（李红）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.8261401, 32.93361331],
    name: "厕所改造（王国有）",
    point: "厕所改造（王国有）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.825689, 32.93395447],
    name: "厕所改造（陈明刚）",
    point: "厕所改造（陈明刚）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.82595315, 32.93827504],
    name: "厕所改造（马富明）",
    point: "厕所改造（马富明）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.82540071, 32.93890455],
    name: "厕所改造（孙庆林）",
    point: "厕所改造（孙庆林）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.826129, 32.938671],
    name: "厕所改造（郝家岭1）",
    point: "厕所改造（郝家岭1）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.82732493, 32.93979288],
    name: "厕所改造（赵启学）",
    point: "厕所改造（赵启学）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.827181, 32.9398],
    name: "厕所改造（李家有）",
    point: "厕所改造（李家有）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
  {
    lnglat: [110.83092172, 32.92323505],
    name: "厕所改造（郭发学）",
    point: "厕所改造（郭发学）",
    equipment: "厕所改造点",
    type: CS,
    source: 4,
    params: "",
    router: ["pipe"],
    genre: "厕所改造点",
  },
];
