<template>
    <div class="s_out">
        <div class="swiper_u" v-if="swiperNum === 1">
            <ul class="header">
                <li class="header-title">预警信息</li>
                <li class="header-title">抓拍时间</li>
                <li class="header-title">布点名称</li>
            </ul>
            <div class="swClass">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide v-for="(item, index) in swiperData" :key="index">
                        <ul class="news-list padding">
                            <li :class="index % 2 !== 0 ? 'olist' : 'jlist'">
                                <div>
                                    {{ item.WarningInformation }}
                                </div>
                                <div>{{ item.time }}</div>
                                <div><span class="rise_arrow"></span>{{ item.name }}</div>
                            </li>
                        </ul>
                    </swiper-slide>
                </swiper>
            </div>
        </div>


        <div class="swiper_2" v-if="swiperNum === 2">
            <ul class="header">
                <li class="header-title">检测点</li>
                <li class="header-title">预警点位</li>
                <li class="header-title">实时点位</li>
                <li class="header-title">警戒状态</li>
            </ul>
            <div class="swClass">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide v-for="(item, index) in swiperData" :key="index">
                        <ul class="news-list padding">
                            <li :class="index % 2 !== 0 ? 'olist' : 'jlist'">
                                <div>
                                    {{ item.WarningInformation }}
                                </div>
                                <div>{{ item.time }}</div>
                                <div>{{ item.name }}</div>
                                <div><span :class="item.state == 1 ? 'stateArrow' : 'stateArrow2'">{{ item.state == 1 ?
                                    '正常' : '预警' }}</span></div>
                            </li>
                        </ul>
                    </swiper-slide>
                </swiper>
            </div>
        </div>


        <div class="swiper_3" v-if="swiperNum === 3">
            <ul class="header">
                <li class="header-title">监测设备</li>
                <li class="header-title">实时时间</li>
                <li class="header-title">报警地点</li>
                <li class="header-title">报警原因</li>
            </ul>
            <div class="swClass">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide v-for="(item, index) in swiperData" :key="index">
                        <ul class="news-list padding">
                            <li :class="index % 2 !== 0 ? 'olist' : 'jlist'">
                                <div>
                                    {{ item.value1 }}
                                </div>
                                <div>{{ item.value2 }}</div>
                                <div>{{ item.value3 }}</div>
                                <div>{{ item.value4 }}</div>
                            </li>
                        </ul>
                    </swiper-slide>
                </swiper>
            </div>
        </div>


        <div class="swiper_4" v-if="swiperNum === 4">
            <ul class="header">
                <li class="header-title">报警地点</li>
                <li class="header-title">处理人员</li>
                <li class="header-title">处理时间</li>
                <li class="header-title">处理结果</li>
            </ul>
            <div class="swClass">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide v-for="(item, index) in swiperData" :key="index">
                        <ul class="news-list padding">
                            <li :class="index % 2 !== 0 ? 'olist' : 'jlist'">
                                <div>
                                    {{ item.value1 }}
                                </div>
                                <div>{{ item.value2 }}</div>
                                <div>{{ item.value3 }}</div>
                                <div>
                                    <span :class="item.state == 0 ? 'stateArrow' : ''" v-if="item.state == 0">已处理</span>
                                    <span :class="item.state == 1 ? 'stateArrow2' : ''"
                                        v-else-if="item.state == 1">未处理</span>
                                </div>
                            </li>
                        </ul>
                    </swiper-slide>
                </swiper>
            </div>
        </div>

        <div class="swiper_u" v-if="swiperNum === 5">
            <ul class="header">
                <li class="header-title">检测点</li>
                <li class="header-title">报警原因</li>
                <li class="header-title">报警时间</li>
                <li class="header-title">是否处理</li>
            </ul>
            <div class="swClass">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide v-for="(item, index) in swiperData" :key="index">
                        <ul class="news-list padding">
                            <li :class="index % 2 !== 0 ? 'olist' : 'jlist'">
                                <div>
                                    {{ item.WarningInformation }}
                                </div>
                                <div>{{ item.name }}</div>
                                <div>{{ item.time }}</div>
                                <div>
                                    <span :class="item.state == 0 ? 'stateArrow' : ''" v-if="item.state == 0">已处理</span>
                                    <span :class="item.state == 1 ? 'stateArrow2' : ''"
                                        v-else-if="item.state == 1">待处理</span>
                                    <span :class="item.state == 2 ? 'stateArrow3' : ''"
                                        v-else-if="item.state == 2">待处理</span>
                                </div>
                            </li>
                        </ul>
                    </swiper-slide>
                </swiper>
            </div>
        </div>


        <div class="swiper_6" v-if="swiperNum === 6">
            <ul class="header">
                <li class="header-title">检测点</li>
                <li class="header-title">实时时间</li>
                <li class="header-title">报警原因</li>
                <li class="header-title">设备状态</li>
            </ul>
            <div class="swClass">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide v-for="(item, index) in swiperData" :key="index">
                        <ul class="news-list padding">
                            <li :class="index % 2 !== 0 ? 'olist' : 'jlist'">
                                <div>
                                    {{ item.WarningInformation }}
                                </div>
                                <div>{{ item.time }}</div>
                                <div>{{ item.name }}</div>
                                <div>
                                    <span :class="item.state == 1 ? 'stateArrow' : 'stateArrow2'">{{ item.state ==
                                        1 ?
                                        '监测中' : '报警中' }}</span>
                                </div>
                            </li>
                        </ul>
                    </swiper-slide>
                </swiper>
            </div>
        </div>


        <div class="swiper_7" v-if="swiperNum === 7">
            <ul class="header">
                <li class="header-title">行政村</li>
                <li class="header-title">养殖场名称</li>
                <li class="header-title">业主姓名</li>
                <li class="header-title">厂址</li>
                <li class="header-title">日产粪便量</li>
                <li class="header-title">无害化处理</li>
                <li class="header-title">是否是禁养区</li>
            </ul>
            <div class="swClass">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide v-for="(item, index) in swiperData" :key="index">
                        <ul class="news-list padding">
                            <li :class="index % 2 !== 0 ? 'olist' : 'jlist'">
                                <div>
                                    {{ item.village }}
                                </div>
                                <div>{{ item.farmName }}</div>
                                <div>{{ item.OwnerName }}</div>
                                <div>{{ item.factory }}</div>
                                <div>{{ item.DailyFecalOutput }}</div>
                                <div>
                                    <span :class="item.InnocentTreatment == 1 ? 'stateArrow2' : 'stateArrow'">{{
                                        item.InnocentTreatment ==
                                            1 ?
                                            '否' : '是' }}</span>
                                </div>
                                <div>
                                    <span :class="item.exclusionArea == 1 ? 'stateArrow' : 'stateArrow2'">{{
                                        item.exclusionArea ==
                                            1 ?
                                            '否' : '是' }}</span>
                                </div>
                            </li>
                        </ul>
                    </swiper-slide>
                </swiper>
            </div>
        </div>


        <div class="swiper_8" v-if="swiperNum === 8">
            <ul class="header">
                <li class="header-title">上报地点</li>
                <li class="header-title">联系方式</li>
                <li class="header-title">上报人</li>
                <li class="header-title">问题类型</li>
                <li class="header-title">上报时间</li>
                <!-- <li class="header-title">处理人员</li> -->
                <!-- <li class="header-title">联系方式</li> -->
            </ul>
            <div class="swClass">
                <swiper class="swiper" :options="swiperOption">
                    <swiper-slide v-for="(item, index) in swiperData" :key="index">
                        <ul class="news-list padding">
                            <li :class="index % 2 !== 0 ? 'olist' : 'jlist'">
                                <div>{{ item.value1 }}</div>
                                <div>{{ item.value2 }}</div>
                                <div>{{ item.value3 }}</div>
                                <div>{{ item.value4 }}</div>
                                <div>{{ item.value5 }}</div>
                                <!-- <div>{{ item.value6 }}</div> -->
                                <!-- <div>{{ item.value7 }}</div> -->
                            </li>
                        </ul>
                    </swiper-slide>
                </swiper>
            </div>
        </div>
    </div>
</template>
<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import config from "./config";
export default {
    components: {
        swiper,
        swiperSlide,
    },
    name: "SW",
    props: {
        itemdeviceId: {
            type: String,
            default: "",
        },
        swiperNum: {
            type: Number,
            default: 1,
        },
        slidesPerView: {
            type: Number,
            default: 3,
        },
        direction: {
            type: String,
            default: "vertical",
        },
        swiperData: {
            type: Array,
            default: () => [],
        },
    },
    computed: {
        swiperOption() {
            return {
                loop: false, // 循环模式选项
                // Swiper 5 use modern CSS Scroll Snap API.
                cssMode: true, // 启用 CSS 闪烁模式
                slidesPerView: this.slidesPerView, // 显示的数量
                spaceBetween: 18, // 轮播间隔
                mousewheel: true, // 启用鼠标滚轮控制
                direction: this.direction, // 垂直滚动
                // 自动播放
                autoplay: {
                    delay: 3000,
                    disableOnInteraction: false,
                },
                navigation: {
                    nextEl: ".swiper-button-next",
                    prevEl: ".swiper-button-prev",
                },
            };
        },
    },
    watch: {
        itemdeviceId: {
            handler(newval) {
                this.itemdeviceId = newval;
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {},
    data() {
        return {
            config: config,
            value: 0,
            isup: false,
            isdown: 0,
            changeSFList: [],
            changeJMList: [],
        };
    },

    mounted() {
        // console.log(this.swiperData, "数据");
    },
};
</script>
<style lang="scss" scoped>
@import "./index.scss";
</style>
