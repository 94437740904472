import VueRouter from "vue-router";

const router = new VueRouter({
  mode: "hash",
  // base: process.env.BASE_URL,
  routes: [
    {
      path: "/login",
      component: () => import("_v/login/indexnew.vue"),
    },
    {
      path: "/",
      //   redirect: "/Home",
      component: () => import("_v/Home"), //首页
    },
    {
      path: "/base",
      component: () => import("_v/base"), //流域概况
    },
    {
      path: "/Develop",
      component: () => import("_v/Develop"), //产业发展
    },
    {
      path: "/EnvironmentalMonitoring",
      component: () => import("_v/EnvironmentalMonitoring"), //环境监测
    },
    {
      path: "/SocialCoGovernance",
      component: () => import("_v/SocialCoGovernance"), //社会共治
    },
    {
      path: "/EarlyWarningAnalysis",
      component: () => import("_v/EarlyWarningAnalysis"), //预警分析
    },
  ],
});

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

router.beforeEach((to, from, next) => {
  const token = sessionStorage.getItem("wlwtoken");
  //   const token = "demo"; // sessionStorage.getItem("wlwtoken");

  if (to.path === "/login") {
    if (token) {
      // 2.如果访问的是登录页面，但是已经登录过了，直接跳转到首页
      return next("/base");
      // }
    } else {
      // 3.如果访问的是登录页面，但是没有登录过，直接放行
      return next();
    }
  }

  if (!token) {
    return next("/login");
  }
  next();
});

export default router;
